import { selectAuth } from '../store/reducers/auth';
import { selectUser } from '../store/reducers/user';
import { selectbusiness } from '../store/reducers/business';
import { useSelector, useDispatch } from 'react-redux';
import ConversationRow from '../_component/ConversationRow';
import { BsThreeDotsVertical, BsFilter, BsSearch } from 'react-icons/bs';
import { useEffect, useState, useRef, useMemo } from 'react';
import { selectConversation } from '../store/reducers/conversations';
import { useConversation } from './context/ConversationContext';
import CustomModal from './CustomModal';
import CustomModal1 from './CustomModal1';
import { Form, Col, Row } from 'react-bootstrap';
import { PATCH, GET } from '../api/api-manager';
import { setAutoReply, getAutoReply } from '../store/reducers/user';
import { getBusinessPhoneList } from '../store/reducers/business';
import { useTranslation, withTranslation } from 'react-i18next';
import { getProfilePic } from '../store/reducers/user';
import { Store } from 'react-notifications-component';
import { useDebounce } from '../_component/hooks/useDebounce';
import { FixedSizeList as List } from 'react-window';

import ListGroup from 'react-bootstrap/ListGroup';
import SelectionList from './SelectionList';
import { useSearch } from './context/SearchContext';

const MODE = {
  NONE: 'NONE',
  NEW_CHAT: 'NEW_CHAT',
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

const renderStringWithComma = strAry => {
  if (strAry.length === 0) {
    return null;
  }

  const html = [];
  for (const key in strAry) {
    const str = strAry[key];

    // html.push(<Badge bg="secondary">{str}</Badge>);
    html.push(
      <span key={key} className="badge bg-secondary">
        {str}
      </span>
    );
    html.push(' ');
  }

  return html;
};

const TopBar = ({ mode, setMode, agentProfilePic, userDisplayName, userRole, toggleMenu, dropdown, setShowModal }) => {
  const { t } = useTranslation();

  switch (mode) {
    case MODE.NONE:
      return (
        <>
          {/* Avatar */}
          <div className="col-2 d-flex justify-content-center align-items-center">
            <img
              className="profile-pic"
              src={
                agentProfilePic
                  ? agentProfilePic
                  : 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000'
              }
              alt="profile_pic"
            />
          </div>
          {/* User display name & role. */}
          <div className="col-8 px-2 d-flex flex-column justify-content-center">
            <div className="d-flex flex-column justify-content-between">
              <h5>{userDisplayName}</h5>
              <div className="fw-normal">{userRole}</div>
            </div>
            <div>
              <span></span>
            </div>
          </div>
          {/* Function buttons */}
          <div className="col-2 d-flex flex-row justify-content-center align-items-center" style={{paddingRight: '22px'}}>
            {/* New chat button */}
            <div
              onClick={() => setMode(MODE.NEW_CHAT)}
              className="btn btn-default"
              style={{ border: 'none', backgroundColor: 'transparent' }}
            >
              <i className="bi bi-chat-right-text-fill" style={{ fontSize: '17px' }}></i>
            </div>
            {/* Dropdown menu */}
            <div
              // onClick={() => toggleMenu()}
              onClick={() => {
                setShowModal(true);
              }}
              className="btn btn-default"
              style={{
                display: 'flex',
                alignItems: 'center',
                border: 'none',
                backgroundColor: 'transparent',
              }}
            >
              <i className="fa fa-plus" style={{ fontSize: '9px' }}></i>
              <i className="fa fa-user" style={{ fontSize: '17px' }}></i>
              {/* <BsThreeDotsVertical style={{ width: '18px', height: '18px' }} />
              <ul
                className={`dropdown-menu${dropdown ? ' show' : ''}`}
                style={{ transform: 'translate3d(-140px, 5px, 0px)' }}
              > */}
              {/* <li key={`li-auto-reply`} id={`li-auto-reply`}>
              <a
                className="dropdown-item"
                href="#1"
                onClick={() => {
                  handleToggleAutoReply();
                }}
              >
                {toggleButtonText}
              </a>
            </li> */}
              {/* <li key={`li-create-new-customer`} id={`li-create-new-customer`}>
                  <a
                    className="dropdown-item"
                    href="#1"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {t('create_new_customer')}
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </>
      );

    case MODE.NEW_CHAT:
      return (
        <div style={{ display: 'flex', gap: '5px', flex: 1, alignItems: 'center', padding: '0 8px 0 8px' }}>
          <button
            type="button"
            className="btn btn-default"
            style={{ backgroundColor: 'transparent', border: 'none' }}
            onClick={() => {
              setMode(MODE.NONE);
            }}
          >
            <i className="bi bi-arrow-left" style={{ fontSize: '26px' }}></i>
          </button>
          <h5 style={{ marginBottom: 0, fontSize: '18px' }}>New Chat</h5>
        </div>
      );

    default:
      break;
  }
};

const ConversationBar = props => {
  const { t } = props;

  // * Custom hooks.
  const dispatch = useDispatch();
  const { profile_pic } = useSelector(selectUser);
  const conversation = useSelector(selectConversation);
  const previousConversation = usePrevious(conversation);
  const { chatSearchOptions, setChatSearchOptions, debouncedChatSearchOptions, setDebouncedChatSearchOptions } =
    useSearch();
  // const [unreadConversations, setUnreadConversations] = useState({});
  const {
    agentList,
    agentGroupList,
    addCustomer,
    updateRoomList,
    updateAgentList,
    updateAgentGroupList,
    updateCustomerAgent,
    markMessageAsRead,
  } = useConversation();
  const { userDisplayName, userRole } = useSelector(selectAuth);
  const { autoReply } = useSelector(selectUser);
  const { businessPhoneList } = useSelector(selectbusiness);

  // * State and ref hooks.
  // > For virtualized list height.
  const conversationListContainerRef = useRef(null);
  const [conversationListContainerHeight, setConversationListContainerHeight] = useState(0);

  // > Mode.
  const [mode, setMode] = useState(MODE.NONE);
  const [isModeLoading, setIsModeLoading] = useState(false);

  const [searchConversation, setSearchConversation] = useState('');

  // new customer
  const [showModal, setShowModal] = useState(false);
  const newPhoneRef = useRef();
  const newNameRef = useRef();
  const newBusinessPhone = useRef();

  // agent assign
  const [showAgentModal, setShowAgentModal] = useState(false);
  // const [assignedAgent, setAssignedAgent] = useState();
  // const [assignedAgentGroups, setAssignedAgentGroups] = useState();
  const [selectedAgents, setSelectedAgents] = useState({}); // Indexed by agent ID
  const [selectedAgentGroups, setSelectedAgentGroups] = useState({}); // Indexed by agent ID
  const [selectedCustomer, setSelectedCustomer] = useState();

  // toggle auto reply
  const [dropdown, setDropdown] = useState(false);
  const [toggleButtonText, setToggleButtonText] = useState('');

  const [agentProfilePic, setAgentProfilePic] = useState(null);

  // Toggle filter
  const [showFilter, setShowFilter] = useState(false);

  // Assign room list to array.
  // ! Test
  // const multiplier = 10000;
  // const conversationList = conversation?.roomList.concat(...Array(multiplier).fill(conversation?.roomList));
  const conversationList = conversation?.roomList;

  // * Effect hooks.
  useEffect(() => {
    dispatch(GET(getProfilePic, 'users/profile_pic', {}));
  }, []);

  // > For tracking the conversation list container height.
  useEffect(() => {
    console.log('useEffect tracking conversation list container is triggered.');
    if (conversationListContainerRef.current) {
      const height = conversationListContainerRef.current.clientHeight;
      console.log('Div height:', height);

      setConversationListContainerHeight(height);
    }
  }, [conversationListContainerRef, setConversationListContainerHeight]);

  useEffect(() => {
    setAgentProfilePic(profile_pic);
  }, [profile_pic]);

  useEffect(() => {
    console.log(`=============== initing ConversationBar ===============`);

    dispatch(GET(getAutoReply, 'users/get_auto_reply_on_off', {}));
    dispatch(GET(getBusinessPhoneList, 'business_profiles', {}));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setToggleButtonText(autoReply ? 'Disable auto reply' : 'Enable auto reply');
  }, [autoReply]);

  useEffect(() => {
    updateAgentList();
    updateAgentGroupList();
  }, [showAgentModal]);

  // * Search conversation(Customer) by keyword / triggered by mode.
  useEffect(() => {
    console.log('Search conversation(Customer) by keyword.', { searchConversation });

    if (searchConversation !== chatSearchOptions?.keyword) {
      console.log('Update search keyword.');

      setChatSearchOptions(prevChatSearchOptions => {
        return {
          ...prevChatSearchOptions,
          keyword: searchConversation,
        };
      });
    }
  }, [searchConversation, JSON.stringify(chatSearchOptions)]);

  // // * Search conversation triggered by mode.
  // useEffect(() => {
  //   console.log('Mode is updated.', { mode, debouncedChatSearchOptions });

  //   let newIncludeNoHistory = undefined;
  //   let new_sort = undefined;

  //   switch (mode) {
  //     case MODE.NONE:
  //       break;

  //     case MODE.NEW_CHAT:
  //       newIncludeNoHistory = true;
  //       new_sort = 'profileNameAsc';
  //       break;

  //     default:
  //       break;
  //   }

  //   if (
  //     newIncludeNoHistory !== debouncedChatSearchOptions.includeNoHistory ||
  //     new_sort !== debouncedChatSearchOptions._sort
  //   ) {
  //     setDebouncedChatSearchOptions(prevDeboundebouncedChatSearchOptions => {
  //       console.log('test 1', prevDeboundebouncedChatSearchOptions);
  //       return {
  //         ...prevDeboundebouncedChatSearchOptions,
  //         includeNoHistory: newIncludeNoHistory,
  //         _sort: new_sort,
  //       };
  //     });
  //   }
  // }, [mode, JSON.stringify(chatSearchOptions)]);

  // Search function
  useEffect(() => {
    console.log('On change mode/search', { mode, debouncedChatSearchOptions });

    setIsModeLoading(true);

    updateRoomList({
      callback: () => {
        console.log('Set is mode loading to false');
        setIsModeLoading(false);
      },
    });
  }, [mode, JSON.stringify(debouncedChatSearchOptions)]);

  // useEffect(() => {
  //   console.log('chatSearchOptions', { chatSearchOptions });
  // }, [chatSearchOptions]);

  // Listen changes of conversation list
  useEffect(() => {
    console.log('On change conversation');

    // console.log({
    //     previousConversation,
    //     conversation
    // })

    if (
      !previousConversation ||
      !Array.isArray(previousConversation?.roomList) ||
      Object.keys(previousConversation.roomList).length === 0
    ) {
      console.log('Previous conversation is not initialized', previousConversation);
      return;
    }

    // const newUnreadConversations = {...unreadConversations};
    const newRoomLastMessageTime = {}; // Indexed by _id;
    const previousRoomLastMessageTime = {}; // Indexed by _id;

    // Index new rooms
    if (Array.isArray(conversationList)) {
      for (const key in conversationList) {
        const room = conversationList[key];

        newRoomLastMessageTime[room._id] = new Date(room.last_msg_time);
      }
    }

    // Index previous rooms
    if (Array.isArray(previousConversation?.roomList)) {
      for (const key in previousConversation.roomList) {
        const room = previousConversation.roomList[key];

        previousRoomLastMessageTime[room._id] = new Date(room.last_msg_time);
      }
    }

    console.log({ newRoomLastMessageTime, previousRoomLastMessageTime, previousConversation });

    // Compare and update unread conversations
    // for (const id of Object.keys(newRoomLastMessageTime)) {
    //     if (!previousRoomLastMessageTime?.[id]) {
    //         // Previously not having this conversation
    //         console.log('Previously not having this conversation')
    //         newUnreadConversations[id] = true;
    //         continue;
    //     }

    //     if (newRoomLastMessageTime[id].getTime() !== previousRoomLastMessageTime[id].getTime()) {
    //         // Message time is different comparing to the previous message time(of the same conversation)
    //         console.log('Message time is different comparing to previous message time(of the same conversation)')
    //         newUnreadConversations[id] = true;
    //         continue;
    //     }
    // }

    // Update unread conversations
    // console.log({newUnreadConversations});
    // setUnreadConversations(newUnreadConversations);
  }, [conversation]);

  // Listen changes of select agent
  useEffect(() => {
    console.log('On change selected agent', selectedAgents);
  }, [selectedAgents]);

  const toggleMenu = () => {
    setDropdown(!dropdown);
  };

  const handleToggleAutoReply = () => {
    let body = { enable_auto_reply: !autoReply };
    dispatch(PATCH(setAutoReply, 'users/toggle_auto_reply', body));
  };

  const agentModal = useMemo(() => {
    return (
      <CustomModal1
        size="lg"
        showModal={showAgentModal}
        setShowModal={setShowAgentModal}
        modal_title={t('agent')}
        onSubmit={() => {
          updateAgent();
        }}
      >
        <Row>
          {/* Agent */}
          <Col>
            <h5 className="">{t('assigned_agent')}</h5>
            <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', fontSize: '16px' }}>
              {renderStringWithComma(
                Object.keys(selectedAgents).length > 0 ? Object.values(selectedAgents).map(value => value.name) : []
              )}
            </div>

            {/* <ListGroup className="mt-4 mb-4">
              {agentList &&
                agentList.map((agent, index) => {
                  return (
                    <ListGroup.Item
                      key={agent._id}
                      action
                      active={selectedAgents?.[agent._id] !== undefined}
                      href={`#agent${index}`}
                      onClick={() => {
                        const newSelectedAgents = { ...selectedAgents };

                        if (newSelectedAgents?.[agent._id] === undefined) {
                          // Add agent
                          newSelectedAgents[agent._id] = agent;
                        } else {
                          // Remove agent
                          delete newSelectedAgents[agent._id];
                        }

                        setSelectedAgents(newSelectedAgents);
                      }}
                    >
                      {agent.name}
                    </ListGroup.Item>
                  );
                })}
            </ListGroup> */}
            <SelectionList
              className="mt-4 mb-4"
              optionList={agentList.map((value, index) => ({ id: value._id, title: value.name }))}
              handleClickOption={(e, option, index) => {
                const newSelectedAgents = { ...selectedAgents };

                if (newSelectedAgents?.[agentList[index]._id] === undefined) {
                  // Add agent
                  newSelectedAgents[agentList[index]._id] = agentList[index];
                } else {
                  // Remove agent
                  delete newSelectedAgents[agentList[index]._id];
                }

                setSelectedAgents(newSelectedAgents);
              }}
              selectedOptions={selectedAgents}
              href={(e, option, index) => `#agent${index}`}
            />
          </Col>

          <Col>
            {/* Agent group */}
            <h5 className="">{t('assigned_agent_group')}</h5>
            <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', fontSize: '16px' }}>
              {renderStringWithComma(
                Object.keys(selectedAgentGroups).length > 0
                  ? Object.values(selectedAgentGroups).map(value => value.name)
                  : []
              )}
            </div>

            {/* <ListGroup className="mt-4">
              {agentGroupList &&
                agentGroupList.map((agentGroup, index) => {
                  return (
                    <ListGroup.Item
                      key={agentGroup._id}
                      action
                      active={selectedAgentGroups?.[agentGroup._id] !== undefined}
                      href={`#agent${index}`}
                      onClick={() => {
                        const newSelectedAgentGroups = { ...selectedAgentGroups };

                        if (newSelectedAgentGroups?.[agentGroup._id] === undefined) {
                          // Add agent
                          newSelectedAgentGroups[agentGroup._id] = agentGroup;
                        } else {
                          // Remove agent
                          delete newSelectedAgentGroups[agentGroup._id];
                        }

                        setSelectedAgentGroups(newSelectedAgentGroups);
                      }}
                    >
                      {agentGroup.name}
                    </ListGroup.Item>
                  );
                })}
            </ListGroup> */}
            <SelectionList
              className="mt-4 mb-4"
              optionList={agentGroupList.map((value, index) => ({ id: value._id, title: value.name }))}
              handleClickOption={(e, option, index) => {
                const newSelectedAgentGroups = { ...selectedAgentGroups };

                if (newSelectedAgentGroups?.[agentGroupList[index]._id] === undefined) {
                  // Add agent
                  newSelectedAgentGroups[agentGroupList[index]._id] = agentGroupList[index];
                } else {
                  // Remove agent
                  delete newSelectedAgentGroups[agentGroupList[index]._id];
                }

                setSelectedAgentGroups(newSelectedAgentGroups);
              }}
              selectedOptions={selectedAgentGroups}
              href={(e, option, index) => `#agent${index}`}
            />
          </Col>
        </Row>
      </CustomModal1>
    );
  });

  // const markMessageAsRead = (id) => {
  //     console.log('on mark message as read', id);
  //     const newUnreadConversations = {...unreadConversations};
  //     delete newUnreadConversations[id];

  //     console.log({newUnreadConversations})
  //     setUnreadConversations(newUnreadConversations);
  // }

  const onModalSubmit = () => {
    if (newPhoneRef.current.value == '' || newNameRef.current.value == '') {
      // alert('please fill in the required info');
      Store.addNotification({
        message: 'please fill in the required info',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: { duration: 5000 },
      });
      throw new Error('not filled');
    } else {
      const phoneNumber = newPhoneRef.current.value;

      addCustomer(newPhoneRef.current.value, newNameRef.current.value, newBusinessPhone.current.value, {
        callback: res => {
          console.log('Add customer callback().', { res });

          if (res?.status === 200) {
            Store.addNotification({
              message: 'The customer has been created successfully.',
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: { duration: 5000 },
            });

            // * Apply phone number search.
            setSearchConversation(phoneNumber);
          } else {
            Store.addNotification({
              message: 'Unable to create this customer.',
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: { duration: 5000 },
            });
          }
        },
      });
    }
  };

  const renderModalContent = () => {
    return (
      <>
        <Form.Group as={Row} controlId="customerName" className="mb-3">
          <Form.Label column sm={3}>
            {t('customer_name')}
          </Form.Label>
          <Col>
            <Form.Control type="input" ref={newNameRef} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="phoneNumber" className="mb-3">
          <Form.Label column sm={3}>
            {t('phone_number')}
          </Form.Label>
          <Col>
            <Form.Control
              type="input"
              onKeyDown={e => {
                if (
                  !/[0-9]/.test(e.key) &&
                  e.key !== 'Backspace' &&
                  e.key !== 'Delete' &&
                  e.key !== 'ArrowRight' &&
                  e.key !== 'ArrowLeft'
                ) {
                  e.preventDefault();
                }
              }}
              ref={newPhoneRef}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="businessPhoneNumbeer" className="mb-3">
          <Form.Label column sm={3}>
            {t('business_phone')}
          </Form.Label>
          <Col>
            <Form.Select ref={newBusinessPhone}>
              {businessPhoneList.map((bphone, index) => {
                return (
                  <option value={bphone} key={index}>
                    {bphone}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
        </Form.Group>
      </>
    );
  };

  const updateAgentModalContent = (customer_id, customer_agents, customer_agent_groups) => {
    console.log(`UPDATING agent modal`, customer_id, customer_agents, customer_agent_groups);

    const newSelectedAgents = {};
    const newSelectedAgentGroups = {};

    // agentList && agentList.forEach(agent => {
    //     if(agent_id == null){
    //         setAssignedAgent(null)
    //     }

    //     if(agent._id == agent_id){
    //         setAssignedAgent(agent)
    //         return
    //     }
    // });
    // setSelectedCustomer(customer_id)
    // updateRoomList()
    // console.log('current agent', assignedAgent);

    if (Array.isArray(agentList) && Array.isArray(customer_agents) && customer_agents.length > 0) {
      for (const agent of agentList) {
        if (!customer_agents.includes(agent._id)) {
          // Database agent groups does not include this agent group
          continue;
        }

        newSelectedAgents[agent._id] = agent;
      }
    }

    if (Array.isArray(agentGroupList) && Array.isArray(customer_agent_groups) && customer_agent_groups.length > 0) {
      for (const agentGroup of agentGroupList) {
        if (!customer_agent_groups.includes(agentGroup._id)) {
          // Database agent groups does not include this agent group
          continue;
        }

        newSelectedAgentGroups[agentGroup._id] = agentGroup;
      }
    }

    setSelectedAgents(newSelectedAgents);
    setSelectedAgentGroups(newSelectedAgentGroups);
    setSelectedCustomer(customer_id);
    // updateRoomList();
  };

  const updateAgent = () => {
    console.log(`updating ${selectedCustomer} agent & agent group`);
    console.log({ selectedAgents, selectedAgentGroups });

    if (selectedCustomer && (Object.keys(selectedAgents).length > 0 || Object.keys(selectedAgentGroups).length > 0)) {
      updateCustomerAgent(selectedCustomer, Object.keys(selectedAgents), Object.keys(selectedAgentGroups));
    }
  };

  return (
    <>
      <div className="p-0" id="chat-list-div" style={{ backgroundColor: '#ffffff', width: 480, }}>
        <div className="d-flex flex-column flex-nowrap" style={{ height: '100%' }}>
          {/* Top BAR start */}
          <div id="top-bar-div" className="d-flex flex-nowrap">
            <TopBar
              mode={mode}
              setMode={newMode => {
                console.log('Mode is updated.', { newMode, debouncedChatSearchOptions });

                let newIncludeNoHistory = undefined;
                let new_sort = undefined;

                switch (newMode) {
                  case MODE.NONE:
                    break;

                  case MODE.NEW_CHAT:
                    newIncludeNoHistory = true;
                    new_sort = 'profileNameAsc';
                    break;

                  default:
                    break;
                }

                if (
                  newIncludeNoHistory !== debouncedChatSearchOptions.includeNoHistory ||
                  new_sort !== debouncedChatSearchOptions._sort
                ) {
                  console.log('Include all contacts');
                  setDebouncedChatSearchOptions(prevDeboundebouncedChatSearchOptions => {
                    console.log('test 1', prevDeboundebouncedChatSearchOptions);
                    return {
                      ...prevDeboundebouncedChatSearchOptions,
                      includeNoHistory: newIncludeNoHistory,
                      _sort: new_sort,
                    };
                  });
                }

                setMode(newMode);
              }}
              agentProfilePic={agentProfilePic}
              userDisplayName={userDisplayName}
              userRole={userRole}
              toggleMenu={toggleMenu}
              dropdown={dropdown}
              setShowModal={setShowModal}
            />
          </div>
          {/* Top BAR end */}

          {/* Search Bar */}
          <div id="search-bar-div" className="d-flex justify-content-center align-items-center">
            <div className="flex-fill">
              <div
                className="input-group position-relative d-inline-flex align-items-center"
                style={{ paddingLeft: '10px', paddingRight: '5px' }}
              >
                <BsSearch style={{ position: 'absolute', left: '20px', zIndex: 100 }} />
                <input
                  className="form-control"
                  type="text"
                  style={{ borderRadius: '9px', paddingLeft: '30px', paddingRight: '30px' }}
                  placeholder={t('search_customer')}
                  name="search-conversation"
                  value={searchConversation}
                  onChange={e => setSearchConversation(e.target.value)}
                />
                {searchConversation && (
                  <svg
                    style={{ position: 'absolute', right: '20px', cursor: 'pointer', zIndex: 100 }}
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={e => setSearchConversation('')}
                  >
                    <path fill="none" stroke="#000" strokeWidth="2" d="M3,3 L21,21 M3,21 L21,3"></path>
                  </svg>
                )}
              </div>
            </div>
            <div style={{ paddingRight: '10px' }}>
              <button
                className="btn btn-default"
                style={{
                  position: 'relative',
                  border: 'none',
                  borderRadius: '50%',
                  padding: '7px 6px 5px 6px',
                  backgroundColor: showFilter ? '#eaeaea' : 'transparent',
                }}
                onClick={e => {
                  setShowFilter(!showFilter);
                }}
              >
                <BsFilter style={{ width: '22px', height: '22px' }} />
                {chatSearchOptions.agents !== undefined &&
                  chatSearchOptions.agentGroups !== undefined &&
                  (Object.keys(chatSearchOptions.agents).length > 0 ||
                    Object.keys(chatSearchOptions.agentGroups).length > 0) && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '-5px',
                        right: '-5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        width: '18px',
                        height: '18px',
                        backgroundColor: '#ffffff',
                        fontSize: '0.6rem',
                      }}
                    >
                      {Object.keys(chatSearchOptions.agents).length + Object.keys(chatSearchOptions.agentGroups).length}
                    </div>
                  )}
              </button>
            </div>
          </div>
          {/* Search Bar end */}
          {/* Additional filters */}
          {showFilter && (
            <div style={{ backgroundColor: 'lightgrey' }}>
              <div
                style={{
                  margin: '0 10px 10px 10px',
                  borderRadius: '10px',
                  padding: '8px 8px 0px 8px',
                  backgroundColor: 'white',
                }}
              >
                <h5 style={{ marginBottom: '10px' }}>Filters</h5>

                <div style={{ overflow: 'auto', maxHeight: '200px' }}>
                  {/* Agent filter */}
                  <div style={{ marginBottom: '12px' }}>
                    <h6 style={{ borderBottom: '1px solid #cccccc', padding: '5px' }}>Agents</h6>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                      {agentList.map((value, index) => (
                        <div
                          key={value._id}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '25px',
                            border: '1px solid #111111',
                            padding: '11px',
                            backgroundColor: chatSearchOptions.agents?.[value._id] ? '#111111' : 'transparent',
                            color: chatSearchOptions.agents?.[value._id] ? '#ffffff' : '#111111',
                            height: '20px',
                            cursor: 'pointer',
                          }}
                          onClick={e => {
                            const newChatSearchOptions = { ...chatSearchOptions };

                            if (newChatSearchOptions?.agents?.[value._id]) {
                              // De-select
                              delete newChatSearchOptions?.agents?.[value._id];
                            } else {
                              // Select
                              newChatSearchOptions.agents[value._id] = true;
                            }

                            setChatSearchOptions(newChatSearchOptions);
                          }}
                        >
                          {value.name}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Agent group filter */}
                  <div style={{ paddingBottom: '8px' }}>
                    <h6 style={{ borderBottom: '1px solid #cccccc', padding: '5px' }}>Agent Groups</h6>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                      {agentGroupList.map((value, index) => (
                        <div
                          key={value._id}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '25px',
                            border: '1px solid #111111',
                            padding: '11px',
                            backgroundColor: chatSearchOptions.agentGroups?.[value._id] ? '#111111' : 'transparent',
                            color: chatSearchOptions.agentGroups?.[value._id] ? '#ffffff' : '#111111',
                            height: '20px',
                            cursor: 'pointer',
                          }}
                          onClick={e => {
                            const newChatSearchOptions = { ...chatSearchOptions };

                            if (newChatSearchOptions?.agentGroups?.[value._id]) {
                              // De-select
                              delete newChatSearchOptions?.agentGroups?.[value._id];
                            } else {
                              // Select
                              newChatSearchOptions.agentGroups[value._id] = true;
                            }

                            setChatSearchOptions(newChatSearchOptions);
                          }}
                        >
                          {value.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div ref={conversationListContainerRef} style={{ flex: 1 }}>
            {!isModeLoading && Array.isArray(conversationList) && conversationList.length > 0 && (
              // Has conversations.
              <List
                height={conversationListContainerHeight}
                width="100%"
                itemCount={conversationList.length + 1}
                itemData={{
                  data: conversationList,
                  rowProps: {
                    mode,
                    setShowAgentModal,
                    updateAgentModalContent,
                  },
                }}
                itemSize={80}
                overscanCount={10}
              >
                {ConversationRow}
              </List>
            )}
            {isModeLoading && (
              <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  style={{ width: '30px', height: '30px' }}
                ></span>
              </div>
            )}
          </div>
        </div>

        {/* Add new customer */}
        <CustomModal1
          showModal={showModal}
          setShowModal={setShowModal}
          modal_title={t('create_new_customer')}
          onSubmit={onModalSubmit}
        >
          {renderModalContent()}
        </CustomModal1>

        {/* Modal for agent assign */}
        {agentModal}
      </div>
    </>
  );
};

export default withTranslation()(ConversationBar);
