import React, { createContext, useContext, useEffect, useState } from 'react'
import io from 'socket.io-client'
import { useDispatch } from "react-redux";
import { POST } from "../../api/api-manager";
import { getRoomList } from "../../store/reducers/conversations";
import { Store } from 'react-notifications-component';
import { useSearch } from './SearchContext';
import { useSystemInfo } from './SystemInfoContext';
import { useSubscription } from './SubscriptionContext';

const ScoketContext = createContext();

export function useSocket() {
    return useContext(ScoketContext)
}
export function SocketProvider({ id, children, db }) {
    const dispatch = useDispatch();
    const { debouncedChatSearchOptions } = useSearch();
    const { setIsAllowUseOfChatGpt } = useSystemInfo()
    const { setActiveSubscriptionPlan } = useSubscription()

    const [socket, setSocket] = useState()
    const [totalUnreadCount, setTotalUnreadCount] = useState(0);
    
    useEffect(() => {
        console.log(`connecting with db ${db}: ${id}`)

        if (id !== '') {
            const newSocket = io(
                process.env.REACT_APP_OVERWRITE_SOCKET_ENDPOINT ? process.env.REACT_APP_OVERWRITE_SOCKET_ENDPOINT : process.env.REACT_APP_API_BASE_URL_DEV,
                { 
                    // path: '/api-server/socket.io/',
                    query: {id:id, db:db}
                }
            )

            setSocket(newSocket)
        } else {
            document.title = `ChatDuck`;
            setTotalUnreadCount(0);

            if (socket !== undefined) {
                socket.disconnect();
            }

            setSocket(undefined);
        }

        return () => {
            if (socket !== undefined) {
                socket.disconnect();
            }
            
            setSocket(undefined);
        }
    }, [id])

    useEffect(() => {
        console.log('socket', socket);
        if (socket === undefined) return;

        const onConnect = () => {
            console.log('Socket connected.')
            console.log(socket.connected); // true
            console.log('emit ready', socket);
            socket.emit('ready');
        }

        const onDisconnect = () => {
            console.log('Socket disconnected.')
        }

        const onSystemInfo = ({ appBuildVersion, totalUnreadCount, isAllowUseOfChatGpt }) => {
            if (Number.isInteger(totalUnreadCount) && totalUnreadCount >= 0) {
                // ! Experimental code. (For conversation update via socket)
                // dispatch(POST(getRoomList, 'conversations' , { agents: Object.keys(debouncedChatSearchOptions.agents), agentGroups: Object.keys(debouncedChatSearchOptions.agentGroups) }))
                
                if (totalUnreadCount > 0) {
                    document.title = `ChatDuck (${totalUnreadCount})`;
                } else {
                    document.title = `ChatDuck`;
                }

                setTotalUnreadCount(totalUnreadCount);
            }

            if (appBuildVersion !== undefined) {
                console.log({
                    localBuildVersion: process.env.REACT_APP_BUILD_VERSION,
                    remoteBuildVersion: appBuildVersion,
                })
                if (process.env.REACT_APP_BUILD_VERSION !== appBuildVersion) {
                    // Local app version does not match the server app version
                    // Prompt user to refresh page and load latest app version
                    console.log('Show app update notification');
                    Store.addNotification({
                        title: 'Update Available',
                        message: 'Click to update the app',
                        type: 'success',
                        insert: "top",
                        container: 'top-right',
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: { duration: 0, click: true, touch: true },
                        onRemoval: (id, removedBy) => {
                            console.log('on notification removal', {id, removedBy});
        
                            window.location.reload();
                        }
                    });
                }
            }

            if (isAllowUseOfChatGpt !== undefined) {
                setIsAllowUseOfChatGpt(isAllowUseOfChatGpt);
            }
        }

        const onSubscriptionUpdated = ({ subscription, }) => {
            console.log('onSubscriptionUpdated()', { subscription });
            setActiveSubscriptionPlan(subscription?.status === 'ACTIVE' ? subscription : undefined);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('system-info', onSystemInfo);
        socket.on('subscription-updated', onSubscriptionUpdated);

        return () => {
            socket.off('connect', onConnect)
            socket.off('disconnect', onDisconnect);
            socket.off('system-info', onSystemInfo)
            socket.off('subscription-updated', onSubscriptionUpdated)
        }
    }, [socket])
    
    
    return (
        <ScoketContext.Provider value={{ socket, totalUnreadCount }}>
            {children}
        </ScoketContext.Provider>
    )
}
