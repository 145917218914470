import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { POST, GET, PATCH } from '../api/api-manager';
import { getAgentGroupList, getParentAgentGroupList } from '../store/reducers/agentGroups';
import { selectAgentGroups, addAgentGroup, updateAgentGroup, deleteAgentGroup } from '../store/reducers/agentGroups';
import Table from 'react-bootstrap/Table';
import CustomModal from '../_component/CustomModal';
import CustomModal1 from '../_component/CustomModal1';
import { Button, Form, InputGroup } from 'react-bootstrap';
import PageTitle from '../_component/PageTitle';
import CustomPagination from '../_component/Pagination';
import CustomPagination1 from '../_component/Pagination1';
import { useTranslation, withTranslation } from 'react-i18next';
import { AppSettings } from '../config/app-settings';
import { apiGenerator } from '../api/api-manager';
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useElements, useStripe, EmbeddedCheckoutProvider, EmbeddedCheckout, } from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';
import { getSubscription } from '../helpers/subscription';
import { useSubscription } from '../_component/context/SubscriptionContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// # Helper functions
// Get the client ChatGPT usage, quota, .etc.
const getChatGptInfo = async () => {
  return new Promise(resolve => {
    apiGenerator({
      method: 'GET',
      path: `client/chat-gpt`,
      body: {},
    }).then(res => {
      return resolve(res?.data ? res.data : undefined);
    });
  });
};

const getSubscriptionPlans = async () => {
  return new Promise(resolve => {
    apiGenerator({
      method: 'GET',
      path: `subscription/plan`,
      body: {},
    }).then(res => {
      return resolve(res?.data ? res.data : undefined);
    });
  });
};

// # Custom Components
const CurrentPlan = ({ subscription, chatGptInfo, ...props }) => {
  const { i18n, t, } = useTranslation();

  const localeLowercase = i18n.language.toLowerCase();
  let displayPlan = subscription?.subscription_plan?.name?.[localeLowercase];

  return (
    <>
      {/* <div className="card-header bg-none p-3 h5 m-0 d-flex align-items-center mt-3">{t('your_current_plan_details')}</div>
      <div className="card-body">
        <table className="table table-borderless table-sm fw-bold m-0">
          <tbody>
            <tr>
              <td className="w-200px">{t('subscription')}</td>
              <td>{displayPlan}</td>
            </tr>
            <tr>
              <td className="w-200px">{t('pricing')}</td>
              <td>
                {subscription.currency}${Math.floor(subscription.pricing * 100) / 100} / {t('month')}
              </td>
            </tr>
            <tr>
              <td className="w-200px">{t('purchase_date_hkt')}</td>
              <td>{new Date(subscription.purchase_date).toLocaleString()}</td>
            </tr>
            <tr>
              <td className="w-200px" style={{ verticalAlign: 'top' }}>
              {t('subscription_period_hkt')}
              </td>
              <td>
                <div style={{ whiteSpace: 'nowrap' }}>{new Date(subscription.purchase_date).toLocaleString()}</div>
                <b>{t('to')}</b>
                <div style={{ whiteSpace: 'nowrap' }}>{new Date(subscription.expiration_date).toLocaleString()}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}

      <div className="card-header bg-none p-3 h5 m-0 d-flex align-items-center">{t('chatgpt_quota')}</div>
      <div className="card-body">
        <table className="table table-borderless table-sm fw-bold m-0">
          <tbody>
            <tr>
              <td className="w-200px">{t('current_usage_quota')}</td>
              <td>{chatGptInfo ? `${chatGptInfo.usage} / ${chatGptInfo.quota}` : 'N/A'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

const SubscribeButton = ({ priceId, onClickBack }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const [isStripeProcessing, setIsStripeProcessing] = useState(false);

  const handleSubscribe = async () => {
    setIsStripeProcessing(true);
    const cardElement = elements.getElement(CardElement);

    // 1. Create Payment Method
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error(error);
      setIsStripeProcessing(false);
      return;
    }

    // 2. Send Payment Method to Backend using apiGenerator
    try {
      const response = await apiGenerator({
        method: "POST",
        path: "/subscription/create-subscription",
        body: {
          email: "customer@example.com", // Replace with actual user email
          paymentMethodId: paymentMethod.id,
          priceId, // Pass HK$1,000 or HK$250 Price ID
        },
      });

      if (response?.data?.success) {
        alert("Subscription successful!");
      } else {
        alert("Subscription failed.");
      }
    } catch (err) {
      console.error(err);
      alert("An error occurred while processing your subscription.");
    }

    setIsStripeProcessing(false);
  };

  return (
    <>
      <CardElement />
      <div className="d-flex justify-content-between mt-2">
        <button className="btn btn-link ps-0" onClick={onClickBack} disabled={isStripeProcessing || !stripe}>
          {t('back')}
        </button>
        <button className="btn btn-primary" onClick={handleSubscribe} disabled={isStripeProcessing || !stripe}>
          {isStripeProcessing ? t('processing_3dots') : t('pay')}
        </button>
      </div>
    </>
  );
};

const StripePaymentSection = ({ selectedStripePriceId }) => {
  const { t } = useTranslation();

  const fetchClientSecret = async () => {
    try {
      const response = await apiGenerator({
        method: "POST",
        path: "/subscription/create-subscription",
        body: { priceId: selectedStripePriceId }, // Only pass priceId
      });
  
      if (response?.data?.success) {
        return response.data.clientSecret; // Return client secret for Embedded Checkout
      } else {
        throw new Error("Failed to fetch client secret");
      }
    } catch (error) {
      console.error("Error fetching client secret:", error);
      return null;
    }
  };

  return <EmbeddedCheckoutProvider
    stripe={stripePromise}
    options={{fetchClientSecret,
      onComplete: () => console.log('stripe onComplete'),
      onLineItemsChange: () => console.log('stripe onLineItemsChange'),
      onShippingDetailsChange: () => console.log('stripe onShippingDetailsChange'),
    }}
  >
    <div className="vr p-0"></div>

    <div className="col-sm-5">
      <div className="card border-0">
        {/* Subscription info */}
        <div className="card-header bg-none p-3 h3 m-0 d-flex align-items-center">
          {t('subscription_payment')}
        </div>
        <div className="card-body">
          <EmbeddedCheckout />
        </div>
      </div>
    </div>
  </EmbeddedCheckoutProvider>;
}

// # Page Components
const Subscription = props => {
  const { i18n, t } = props;

  const appSettings = useContext(AppSettings);
  const { activeSubscriptionPlan: subscription, setActiveSubscriptionPlan } = useSubscription()
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  // const [subscription, setSubscription] = useState();
  const [chatGptInfo, setChatGptInfo] = useState();
  const [selectedStripePriceId, setSelectedStripePriceId] = useState(undefined);
  const [pricingTableClientSecret, setPricingTableClientSecret] = useState(undefined);

  const localeLowercase = i18n.language.toLowerCase();

  // In-component helper functions
  // * Init subscirption.
  const initSubscription = currentSubscription => {
    console.log('initSubscription()', { currentSubscription });
    if (currentSubscription === undefined) {
      console.log('No existing subscription');
      return;
    }

    // setSubscription({
    //   currency: currentSubscription?.subscription_plan?.currency,
    //   pricing: currentSubscription?.subscription_plan?.price,
    //   purchaseDate: currentSubscription.purchase_date,
    //   expiration_date: currentSubscription.expiration_date,
    //   subscriptionPlan: currentSubscription?.subscription_plan,
    // });

    if (typeof currentSubscription !== 'undefined' && currentSubscription?._id !== subscription?._id) {
      setActiveSubscriptionPlan(currentSubscription);
    }
  };

  const initChatGptInfo = chatGptData => {
    // * Init ChatGPT info.
    const newChatGptInfo = {
      usage: 0,
      quota: 0,
    };

    if (
      typeof chatGptData?.isChatGptEnabled !== 'boolean' ||
      chatGptData?.isChatGptEnabled === false ||
      typeof chatGptData?.chatGptQuota !== 'number' ||
      chatGptData?.chatGptQuota === 0
    ) {
      console.log('ChatGPT is not available.');
      return;
    }

    newChatGptInfo.quota = chatGptData.chatGptQuota;

    if (typeof chatGptData.chatGptUsage === 'number') {
      newChatGptInfo.usage = chatGptData.chatGptUsage;
    }

    setChatGptInfo(newChatGptInfo);
  };

  const loadSubscription = async ({ skipGetSessionStatus } = {}) => {
    if (!skipGetSessionStatus) {
      await getSessionStatus();
    }
    
    let getSubscriptionPlansRes = getSubscriptionPlans();
    let getSubscriptionRes = getSubscription();
    let getChatGptInfoRes = getChatGptInfo();

    try {
      const pricingTableClientSecret = await fetchPricingTableClientSecret();
      if (typeof pricingTableClientSecret !== 'undefined') setPricingTableClientSecret(pricingTableClientSecret);
    } catch (error) {
      console.error("Error fetching pricing table client secret:", error);
    }


    getSubscriptionPlansRes = await getSubscriptionPlansRes;
    getSubscriptionRes = await getSubscriptionRes;
    getChatGptInfoRes = await getChatGptInfoRes;

    console.log({ getSubscriptionPlansRes, getSubscriptionRes, getChatGptInfoRes });

    if (Array.isArray(getSubscriptionPlansRes?.data)) {
      setSubscriptionPlans(getSubscriptionPlansRes.data);
    }
    initSubscription(getSubscriptionRes?.params?.[0]?.last_subscription);
    initChatGptInfo(getChatGptInfoRes?.data);
  }

  const getSessionStatus = async () => {
    let closePopup = false;
    let showErrorPopup = false;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
  
    if (sessionId === null) return; // No session ID.
  
    try {
      Swal.fire({
        html: t('processing_payment'),
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  
      const res = await apiGenerator({
        method: 'GET',
        path: `subscription/session-status`,
        query: {
          params: { session_id: sessionId },
        },
      });
  
      closePopup = false;
  
      if (res?.data?.paymentSession?.status === 'paid') {
        try {
          await loadSubscription({ skipGetSessionStatus: true });

          Swal.fire({
            title: t('completed_symbol_em'),
            text: t('payment_processed_subscription_activated'),
            icon: "success",
          });
        } catch (error) {
          console.error("Failed to load subscription:", error);
        }
      } else {
        showErrorPopup = true;
      }
  
      return res?.data;
    } catch (error) {
      console.error(error);
      showErrorPopup = true;
    } finally {
      if (closePopup) Swal.close();

      if (showErrorPopup) {
        Swal.fire({
          title: t('payment_failed'),
          text: t('payment_failed_contact_customer_service'),
          icon: "error",
        });
      }
    }
  };

  const fetchPricingTableClientSecret = async () => {
    try {
      const response = await apiGenerator({
        method: "POST",
        path: "/subscription/stripe-pricing-table-client-secret",
      });
  
      if (response?.data?.success) {
        return response.data.clientSecret; // Return client secret for Embedded Checkout
      } else {
        throw new Error("Failed to fetch client secret");
      }
    } catch (error) {
      console.error("Error fetching client secret:", error);
      return null;
    }
  };

  const handleManageSubscriptionClick = async () => {
    try {
      const response = await apiGenerator({
        method: "POST",
        path: "/subscription/stripe-portal-session",
      });
  
      if (response?.data?.success) {
        window.location.href = response.data.url;
      } else {
        throw new Error("Failed to fetch client secret");
      }
    } catch (error) {
      console.error("Error fetching client secret:", error);
      return null;
    }
  };

  // General UI settings (Template)
  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');
  }, [appSettings]);

  useEffect(() => {
    loadSubscription();
  }, []);

  return (
    <>
      <Container>
        {/* Breadcrumb */}
        <div className="d-flex align-items-center mb-3">
          <div>
            <h1 className="page-header mb-0">{t('subscription')}</h1>
          </div>
        </div>

        {/* Main content */}
        <div className="row">
          {typeof subscription !== 'undefined' && (
            <div className="col-sm-auto mb-4 m-auto">
              <div className="card border-0">
                {/* Subscription info */}
                <div className="card-header bg-none p-3 h5 m-0 d-flex align-items-center">
                  Current subscription
                </div>
                <div className="card-body bg-none p-3 m-0 mb-3 d-flex align-items-start justify-content-between">
                  <div>
                    <div className='h4' style={{ fontWeight: 400, }}>
                      {subscription?.subscription_plan?.name?.[localeLowercase]}
                    </div>
                    <div className="h3 mt-2" style={{ fontWeight: 700, }}>
                      {subscription?.subscription_plan?.currency}
                      {subscription?.amount_after_discount} / {subscription?.subscription_plan?.period === 'MONTHLY' ? t('month') : t('year')}
                    </div>
                  </div>
                  
                  <button className="btn btn-primary d-flex ms-5" onClick={() => handleManageSubscriptionClick()}>
                    {t('manage')}
                  </button>
                </div>

                <CurrentPlan subscription={subscription} chatGptInfo={chatGptInfo} />
              </div>
            </div>
          )}

          {typeof subscription === 'undefined' && (
            <div className="col-sm-12 mb-3">
              <stripe-pricing-table
                pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
                publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                customer-session-client-secret={pricingTableClientSecret}>
              </stripe-pricing-table>
            </div>
          )}

          {/* Dynamic plan */}
          {/* {subscriptionPlans.map(plan => { */}
          {[].map(plan => {
            if (typeof selectedStripePriceId !== 'undefined' && plan.stripe_price_id !== selectedStripePriceId) return null;

            return <div className="col-sm" key={plan._id}>
              <div className="card border-0">
                {/* Subscription info */}
                <div className="card-header bg-none p-3 h2 m-0 d-flex align-items-center">
                  {plan.name?.[localeLowercase]}
                  {localeLowercase === 'en_us' ? ' ' : ''}
                  {t('plan')}
                  {subscription?.subscription_plan?._id === plan._id && (
                    <span
                      className="badge bg-primary mx-2"
                      style={{ fontSize: '16px', paddingTop: '4px', paddingBottom: '4px' }}
                    >
                      {t('active_plan')}
                    </span>
                  )}
                  <div className="h5 ms-auto text-decoration-none text-gray-500">
                    {plan.currency}
                    {plan.price} / {t('month')}
                  </div>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    {/* Display description */}
                    <p>{plan.description?.[localeLowercase]}</p>
                  </div>
                  <table className="table table-borderless table-sm fw-bold m-0">
                    <tbody>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <i
                              className="fa fa-fw fa-circle-check me-2 text-green"
                              style={{ height: 18, alignContent: 'center' }}
                            ></i>
                            <span>
                              {t('subscription_number_of_agent_accounts')}
                              {t('symbol_colon')}{' '}
                              {plan.num_agent_accounts === -1 ? t('unlimited') : plan.num_agent_accounts}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <i
                              className="fa fa-fw fa-circle-check me-2 text-green"
                              style={{ height: 18, alignContent: 'center' }}
                            ></i>
                            <span>
                              {t('subscription_number_of_groups')}
                              {t('symbol_colon')} {plan.num_groups === -1 ? t('unlimited') : plan.num_groups}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <i
                              className="fa fa-fw fa-circle-check me-2 text-green"
                              style={{ height: 18, alignContent: 'center' }}
                            ></i>
                            <span>
                              {t('subscription_number_of_customers')}
                              {t('symbol_colon')} {plan.num_customers === -1 ? t('unlimited') : plan.num_customers}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <i
                              className="fa fa-fw fa-circle-check me-2 text-green"
                              style={{ height: 18, alignContent: 'center' }}
                            ></i>
                            <span>
                              {t('subscription_chatgpt_integration')}
                              {t('symbol_colon')}{' '}
                              {plan.chatgpt_integration === 0
                                ? t('included')
                                : `${plan.currency} ${plan.chatgpt_integration} / ${t('month')}`}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <i
                              className="fa fa-fw fa-circle-check me-2 text-green"
                              style={{ height: 18, alignContent: 'center' }}
                            ></i>
                            <span>
                              {t('subscription_customer_support')}
                              {t('symbol_colon')} {plan.customer_support?.[localeLowercase]}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <i
                              className="fa fa-fw fa-circle-check me-2 text-green"
                              style={{ height: 18, alignContent: 'center' }}
                            ></i>
                            <span>
                              {t('subscription_additional_agent_account_1')} {plan.additional_agent_account}{' '}
                              {t('subscription_additional_agent_account_2')}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {typeof subscription?.subscription_plan?._id === 'undefined' &&
                    (typeof selectedStripePriceId === 'undefined' ?
                      <button className="btn btn-primary d-flex ms-auto mt-2" onClick={() => setSelectedStripePriceId(plan.stripe_price_id)}>
                        {t('subscribe')}
                      </button>
                      :
                      <button className="btn btn-link d-flex ms-auto mt-2 pe-0" onClick={() => setSelectedStripePriceId(undefined)}>
                        {t('view_other_plans')}
                      </button>
                    )
                  }
                </div>

                {subscription?.subscription_plan?._id === plan?._id && <CurrentPlan subscription={subscription} chatGptInfo={chatGptInfo} />}
              </div>
            </div>
          })}

          {/* Stripe payment UI */}
          {false && selectedStripePriceId && 
            <StripePaymentSection selectedStripePriceId={selectedStripePriceId} />
          }

          {/* Starter plan */}
          {false && (
            <div className="col-sm-4" style={{ display: 'none' }}>
              <div className="card border-0">
                {/* Subscription info */}
                <div className="card-header bg-none p-3 h2 m-0 d-flex align-items-center">
                  {t('starter')}
                  {subscription?.plan === 'starter' && (
                    <span
                      className="badge bg-primary mx-2"
                      style={{ fontSize: '16px', paddingTop: '4px', paddingBottom: '4px' }}
                    >
                      {t('active_plan')}
                    </span>
                  )}
                  <div className="h5 ms-auto text-decoration-none text-gray-500">HKD$299 / {t('month')}</div>
                </div>
                <div className="card-body">
                  <table className="table table-borderless table-sm fw-bold m-0">
                    <tbody>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_number_of_agent_accounts')} 5
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_number_of_groups')} 2
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_number_of_monthly_active_contacts')} 500
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_usage_reports')}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_automatic_responses')}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_system_alerts')}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_additional_agent_account_hkd_per_agent_per_month_1')}50{' '}
                          {t('subscription_additional_agent_account_hkd_per_agent_per_month_2')}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_additional_group_hkd_per_group_per_month_1')}100{' '}
                          {t('subscription_additional_group_hkd_per_group_per_month_2')}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {subscription?.plan === 'starter' && (
                  <CurrentPlan subscription={subscription} chatGptInfo={chatGptInfo} />
                )}

                <Elements stripe={stripePromise}>
                  {/* $250 */}
                  <SubscribeButton priceId="price_1QzXk9CVV7531kp0s1LMs3hU" /> {/* Replace with actual Price ID */}
                </Elements>
              </div>
            </div>
          )}

          {/* Business plan */}
          {false && (
            <div className="col-sm-4" style={{ display: 'none' }}>
              <div className="card border-0">
                {/* Subscription info */}
                <div className="card-header bg-none p-3 h2 m-0 d-flex align-items-center">
                  {t('business')}
                  {subscription?.plan === 'business' && (
                    <span
                      className="badge bg-primary mx-2"
                      style={{ fontSize: '16px', paddingTop: '4px', paddingBottom: '4px' }}
                    >
                      {t('active_plan')}
                    </span>
                  )}
                  <div className="h5 ms-auto text-decoration-none text-gray-500">HKD$899 / {t('month')}</div>
                </div>
                <div className="card-body">
                  <table className="table table-borderless table-sm fw-bold m-0">
                    <tbody>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_number_of_agent_accounts')} 20
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_number_of_groups')} 5
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_number_of_monthly_active_contacts')} 2,500
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_advanced_reports')}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_automatic_responses')}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_system_alerts')}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_additional_agent_account_hkd_per_agent_per_month_1')}40{' '}
                          {t('subscription_additional_agent_account_hkd_per_agent_per_month_2')}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_additional_group_hkd_per_group_per_month_1')}80{' '}
                          {t('subscription_additional_group_hkd_per_group_per_month_2')}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {subscription?.plan === 'business' && (
                  <CurrentPlan subscription={subscription} chatGptInfo={chatGptInfo} />
                )}

                <Elements stripe={stripePromise}>
                  {/* $1,000 */}
                  <SubscribeButton priceId="price_1QzXl0CVV7531kp0WKvWHRzX" />
                </Elements>
              </div>
            </div>
          )}

          {/* Enterprise plan */}
          {false && (
            <div className="col-sm-4" style={{ display: 'none' }}>
              <div className="card border-0">
                {/* Subscription info */}
                <div className="card-header bg-none p-3 h2 m-0 d-flex align-items-center">
                  {t('enterprise')}
                  {subscription?.plan === 'enterprise' && (
                    <span
                      className="badge bg-primary mx-2"
                      style={{ fontSize: '16px', paddingTop: '4px', paddingBottom: '4px' }}
                    >
                      {t('active_plan')}
                    </span>
                  )}
                  <div className="h5 ms-auto text-decoration-none text-gray-500">HKD$2,499 / {t('month')}</div>
                </div>
                <div className="card-body">
                  <table className="table table-borderless table-sm fw-bold m-0">
                    <tbody>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_number_of_agent_accounts')} 50
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_number_of_groups')} 15
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_number_of_monthly_active_contacts')} 10,000
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_advanced_reports')}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_automatic_responses')}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_system_alerts')}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_additional_agent_account_hkd_per_agent_per_month_1')}30{' '}
                          {t('subscription_additional_agent_account_hkd_per_agent_per_month_2')}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-100" colSpan={2}>
                          <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                          {t('subscription_additional_group_hkd_per_group_per_month_1')}60{' '}
                          {t('subscription_additional_group_hkd_per_group_per_month_2')}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {subscription?.plan === 'enterprise' && (
                  <CurrentPlan subscription={subscription} chatGptInfo={chatGptInfo} />
                )}
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default withTranslation()(Subscription);
