import React,{ useEffect, useState, useContext } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector, useDispatch } from 'react-redux'
import { POST } from "../api/api-manager"
import { selectAuth, login, checkSession } from '../store/reducers/auth';
import { withTranslation } from 'react-i18next'
import { AppSettings } from '../config/app-settings';
import DropdownLanguage from '../components/header/dropdown/language';

const Login1 = (props) => {
    const { t } = props;
    const dispatch = useDispatch();
    const authState = useSelector(selectAuth);
    const appSettings = useContext(AppSettings);

    const [organization, setOrganization] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);

    useEffect(() => {
        setOrganization(localStorage.getItem('organization') || "");
        setUsername(localStorage.getItem('username') || "");
        setRememberMe(localStorage.getItem('rememberMe') === 'true');
    }, []);

    useEffect(() => {
        console.log('app settings context', appSettings);

        appSettings.handleSetAppSidebarNone(true);
        appSettings.handleSetAppHeaderNone(true);
        appSettings.handleSetAppContentClass('p-0');

        // return () => {
        //     appSettings.handleSetAppSidebarNone(false);
        //     appSettings.handleSetAppHeaderNone(false);
        //     appSettings.handleSetAppContentClass('');
        // }
    }, [appSettings])

    const handleSubmit = (e) => {
        e.preventDefault();

        let body={ organization, username, password };

        if (rememberMe) {
            localStorage.setItem('rememberMe', 'true');
            localStorage.setItem('organization', organization);
            localStorage.setItem('username', username);
        } else {
            localStorage.removeItem('rememberMe');
            localStorage.removeItem('organization');
            localStorage.removeItem('username');
        }

        dispatch( POST(login,"users/login",body) );
    }

    return (
        <>
            <div className="login login-v1">
                <div className="login-container">
                    <div className="login-header">
                        <div className="brand">
                            <div className="d-flex align-items-center">
                                <Image src={`${process.env.PUBLIC_URL}/logo.png`} fluid />
                            </div>
                        </div>
                    </div>
                    <div className="login-body">
                        <div className="login-content fs-13px">
                            <form onSubmit={handleSubmit}>
                                <div className="form-floating mb-20px">
                                    <input type="text" className="form-control fs-13px h-45px" id="organization" value={organization} onChange={(e) => setOrganization(e.target.value)} />
                                    <label htmlFor="organization" className="d-flex align-items-center py-0">{t('organization')}</label>
                                </div>
                                <div className="form-floating mb-20px">
                                    <input type="text" className="form-control fs-13px h-45px" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                    <label htmlFor="username" className="d-flex align-items-center py-0">{t('username')}</label>
                                </div>
                                <div className="form-floating mb-10px">
                                    <input type="password" className="form-control fs-13px h-45px" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    <label htmlFor="password" className="d-flex align-items-center py-0">{t('password')}</label>
                                </div>
                                <div className="form-floating mb-25px">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="rememberMe"
                                            checked={rememberMe}
                                            onChange={(e) => setRememberMe(e.target.checked)}
                                        />
                                        <label className="form-check-label" htmlFor="rememberMe">
                                            {t('label_remember_me')}
                                        </label>
                                    </div>
                                </div>
                                <div className="login-buttons">
                                    <button type="submit" className="btn h-45px btn-primary d-block w-100 btn-lg">{t('sign_in')}</button>
                                </div>
                                <div className="mt-3" style={{textAlign:'center', color:'red'}}>{authState.loginErrorMsg}</div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="floating-lang-picker" style={{ position: 'fixed', top: '15px', right: '15px' }}>
                <DropdownLanguage />
            </div>
        </>
    )
}

export default withTranslation()(Login1)