import React, { useEffect, useState, useContext } from 'react';
import { Button, Col, Row, Table, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getMessageTemplateList, apiGenerator } from '../api/api-manager';
import { Link, useNavigate } from 'react-router-dom';
import PageTitle from '../_component/PageTitle';
import CustomPagination from '../_component/Pagination';
import CustomPagination1 from '../_component/Pagination1';
import { AppSettings } from '../config/app-settings';
import { Store } from 'react-notifications-component';

import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import CustomModal1 from '../_component/CustomModal1';

import { usePermissions } from '../_component/context/AuthContext';
import { useDebounce } from '../_component/hooks/useDebounce';
import { components } from 'react-select';

const CATEGORIES = [
  { label: 'Marketing', value: 'MARKETING' },
  { label: 'Utility', value: 'UTILITY' },
  { label: 'Authentication', value: 'AUTHENTICATION' },
  // ! Not supported in our app yet.
];
// ! To be deleted.
// const COMPONENT_TYPES = [
//   { label: 'Header', value: 'HEADER' },
//   { label: 'Body', value: 'BODY' },
//   { label: 'Footer', value: 'FOOTER' },
//   { label: 'Buttons', value: 'BUTTONS' },
// ];
// * For header.
const COMPONENT_HEADER_FORMATS = [
  { label: 'Text', value: 'TEXT' },
  // ! Not supported in our app yet.
  // { label: 'Image', value: 'IMAGE' },
  // { label: 'Video', value: 'VIDEO' },
  // { label: 'Document', value: 'DOCUMENT' },
  // { label: 'Location', value: 'LOCATION' },
];
const MESSAGE_TEMPLATE_SUPPORTED_LANGUAGES = [
  // { language: 'Afrikaans', code: 'af' },
  // { language: 'Albanian', code: 'sq' },
  // { language: 'Arabic', code: 'ar' },
  // { language: 'Azerbaijani', code: 'az' },
  // { language: 'Bengali', code: 'bn' },
  // { language: 'Bulgarian', code: 'bg' },
  // { language: 'Catalan', code: 'ca' },
  // { language: 'Chinese (CHN)', code: 'zh_CN' },
  { language: 'Chinese (HKG)', code: 'zh_HK' },
  // { language: 'Chinese (TAI)', code: 'zh_TW' },
  // { language: 'Croatian', code: 'hr' },
  // { language: 'Czech', code: 'cs' },
  // { language: 'Danish', code: 'da' },
  // { language: 'Dutch', code: 'nl' },
  { language: 'English', code: 'en' },
  // { language: 'English (UK)', code: 'en_GB' },
  // { language: 'English (US)', code: 'en_US' },
  // { language: 'Estonian', code: 'et' },
  // { language: 'Filipino', code: 'fil' },
  // { language: 'Finnish', code: 'fi' },
  // { language: 'French', code: 'fr' },
  // { language: 'Georgian', code: 'ka' },
  // { language: 'German', code: 'de' },
  // { language: 'Greek', code: 'el' },
  // { language: 'Gujarati', code: 'gu' },
  // { language: 'Hausa', code: 'ha' },
  // { language: 'Hebrew', code: 'he' },
  // { language: 'Hindi', code: 'hi' },
  // { language: 'Hungarian', code: 'hu' },
  // { language: 'Indonesian', code: 'id' },
  // { language: 'Irish', code: 'ga' },
  // { language: 'Italian', code: 'it' },
  // { language: 'Japanese', code: 'ja' },
  // { language: 'Kannada', code: 'kn' },
  // { language: 'Kazakh', code: 'kk' },
  // { language: 'Kinyarwanda', code: 'rw_RW' },
  // { language: 'Korean', code: 'ko' },
  // { language: 'Kyrgyz (Kyrgyzstan)', code: 'ky_KG' },
  // { language: 'Lao', code: 'lo' },
  // { language: 'Latvian', code: 'lv' },
  // { language: 'Lithuanian', code: 'lt' },
  // { language: 'Macedonian', code: 'mk' },
  // { language: 'Malay', code: 'ms' },
  // { language: 'Malayalam', code: 'ml' },
  // { language: 'Marathi', code: 'mr' },
  // { language: 'Norwegian', code: 'nb' },
  // { language: 'Persian', code: 'fa' },
  // { language: 'Polish', code: 'pl' },
  // { language: 'Portuguese (BR)', code: 'pt_BR' },
  // { language: 'Portuguese (POR)', code: 'pt_PT' },
  // { language: 'Punjabi', code: 'pa' },
  // { language: 'Romanian', code: 'ro' },
  // { language: 'Russian', code: 'ru' },
  // { language: 'Serbian', code: 'sr' },
  // { language: 'Slovak', code: 'sk' },
  // { language: 'Slovenian', code: 'sl' },
  // { language: 'Spanish', code: 'es' },
  // { language: 'Spanish (ARG)', code: 'es_AR' },
  // { language: 'Spanish (SPA)', code: 'es_ES' },
  // { language: 'Spanish (MEX)', code: 'es_MX' },
  // { language: 'Swahili', code: 'sw' },
  // { language: 'Swedish', code: 'sv' },
  // { language: 'Tamil', code: 'ta' },
  // { language: 'Telugu', code: 'te' },
  // { language: 'Thai', code: 'th' },
  // { language: 'Turkish', code: 'tr' },
  // { language: 'Ukrainian', code: 'uk' },
  // { language: 'Urdu', code: 'ur' },
  // { language: 'Uzbek', code: 'uz' },
  // { language: 'Vietnamese', code: 'vi' },
  // { language: 'Zulu', code: 'zu' },
];

// * Helper functions.
const getMessageTemplateCountLimit = async () => {
  return new Promise(resolve => {
    apiGenerator({
      method: 'GET',
      path: `client/message-template-count-limit`,
      body: {},
    }).then(res => {
      return resolve(res?.data ? res.data : undefined);
    });
  });
};

const MessageTemplates = props => {
  const { t } = props;
  // const dispatch = useDispatch();
  const appSettings = useContext(AppSettings);

  // Disable add message template button.
  const [disableAddMessageTemplate, setDisableAddMessageTemplate] = useState(true);

  // Search fields
  const [searchKeyword, setSearchKeyword] = useState('');
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchKeyword, 350);

  const { permissions } = usePermissions();
  const [messageTemplateList, setMessageTemplateList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteMessageTemplate, setSelectedDeleteMessageTemplate] = useState(undefined);

  const [page, setPage] = useState(1);
  const [paginationParams, setPaginationParams] = useState([]);
  const limit = 2;

  const navigate = useNavigate();

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');

    // return () => {
    //   appSettings.handleSetAppSidebarNone(false);
    //   appSettings.handleSetAppHeaderNone(false);
    // };
  }, [appSettings]);

  // Get message template limit.
  useEffect(() => {
    getMessageTemplateCountLimit().then(res => {
      if (isNaN(res?.data?.countLimit)) return setDisableAddMessageTemplate(false);

      if (isNaN(res?.data?.count)) return setDisableAddMessageTemplate(true);

      setDisableAddMessageTemplate(res.data.count >= res.data.countLimit);
    });
  }, []);

  useEffect(() => {
    updateMessageTemplateList();
  }, [debouncedSearchKeyword, page]);

  useEffect(() => {
    console.log(`messageTemplateList`, messageTemplateList);
  }, [messageTemplateList]);

  const onDeleteModalSubmit = () => {
    apiGenerator({
      method: 'DELETE',
      path: `message_templates/${selectedDeleteMessageTemplate._id}`,
      body: {},
    }).then(res => {
      // alert(res.data.message);
      Store.addNotification({
        message: res.data.message,
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: { duration: 5000 },
      });

      if (res.data.status) {
        updateMessageTemplateList();
      }
    });
  };

  const editMessageTemplate = messageTemplate => {
    console.log(`editing message template...`, messageTemplate);
    navigate('/message_templates/add', { state: { messageTemplate: messageTemplate } });
  };

  const handleDeleteMessageTemplate = messageTemplate => {
    setSelectedDeleteMessageTemplate(messageTemplate);
    setShowDeleteModal(true);
  };

  const updateMessageTemplateList = () => {
    getMessageTemplateList({
      keyword: debouncedSearchKeyword,
      page: page,
      limit: limit,
    }).then(res => {
      let response = res.data;
      console.log({ response });
      if (response.status) {
        let newList = [];

        for (const messageTemplate of response.params.docs) {
          newList.push(messageTemplate);
        }

        setMessageTemplateList(newList);
        setPaginationParams({
          totalDocs: response.params.totalDocs,
          limit: response.params.limit,
          page: response.params.page,
          totalPages: response.params.totalPages,
          hasPrevPage: response.params.hasPrevPage,
          hasNextPage: response.params.hasNextPage,
          prevPage: response.params.prevPage,
          nextPage: response.params.nextPage,
        });
      }
    });
  };

  return (
    <>
      <Container>
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={'/'}>Home</Link>
              </li>
              <li className="breadcrumb-item active">{t('message_template_management')}</li>
            </ul>
            <h1 className="page-header mb-0">{t('message_template_management')}</h1>
          </div>
          <div className="ms-auto">
            {permissions?.add_role && (
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip>{t('max_agent_group_reached')}</Tooltip>}
                show={disableAddMessageTemplate ? undefined : false}
              >
                <Link
                  to={'/message_templates/add'}
                  onClick={disableAddMessageTemplate ? e => e.preventDefault() : undefined}
                  style={disableAddMessageTemplate ? { cursor: 'not-allowed' } : undefined}
                >
                  <Button
                    variant="primary"
                    className="px-4 btn-rounded rounded-pill"
                    disabled={disableAddMessageTemplate}
                  >
                    <i className="fa fa-plus fa-lg me-2 ms-n2 text-white"></i> {t('add_message_template')}
                  </Button>
                </Link>
              </OverlayTrigger>
            )}
          </div>
        </div>

        <div className="card border-0">
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              {/* Search bar */}
              <div div className="input-group mb-3">
                <div
                  className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                  style={{ zIndex: 10 }}
                >
                  <i className="fa fa-search opacity-5"></i>
                </div>
                <input
                  type="text"
                  className="form-control px-35px bg-light"
                  style={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
                  placeholder={t('search_name')}
                  value={searchKeyword}
                  onChange={e => {
                    setSearchKeyword(e.target.value);
                  }}
                />
              </div>

              {/* Table */}
              <div className="table-responsive mb-3">
                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: '1%', whiteSpace: 'nowrap' }}>#</th>
                      {(permissions?.edit_role || permissions?.delete_role) && (
                        <th style={{ width: '1%', whiteSpace: 'nowrap' }}>{t('action')}</th>
                      )}
                      <th>{t('name')}</th>
                      <th>{t('category')}</th>
                      <th>{t('type')}</th>
                      <th>{t('language')}</th>
                      <th>{t('status')}</th>
                      <th>{t('enabled')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {messageTemplateList &&
                      messageTemplateList.length > 0 &&
                      messageTemplateList.map((message_template, index) => {
                        let tdCategory;
                        let tdType;
                        let tdLanguage;

                        const findCategoryRes = CATEGORIES.find(
                          category => category.value === message_template.category
                        );
                        const findTypeRes = message_template.components.find(component => component.type === 'HEADER');
                        const findLanguageRes = MESSAGE_TEMPLATE_SUPPORTED_LANGUAGES.find(
                          language => language.code === message_template.language
                        );

                        tdCategory =
                          typeof findCategoryRes?.label !== 'undefined'
                            ? findCategoryRes?.label
                            : message_template.category;
                        tdType = typeof findTypeRes?.format !== 'undefined' ? findTypeRes.format : '';
                        tdLanguage =
                          typeof findLanguageRes?.language !== 'undefined'
                            ? findLanguageRes?.language
                            : message_template.language;

                        return (
                          <tr key={index}>
                            <td style={{ verticalAlign: 'super' }}>{index + 1}</td>
                            {(permissions?.edit_role || permissions?.delete_role) && (
                              <td nowrap="true" className="with-btn" style={{ verticalAlign: 'super' }}>
                                {permissions?.edit_role && (
                                  <button
                                    className="btn btn-sm btn-primary w-60px me-1"
                                    onClick={() => editMessageTemplate(message_template)}
                                    // disabled={message_template.status === 'PENDING'}
                                  >
                                    {t('edit')}
                                  </button>
                                )}
                                {permissions?.delete_role && (
                                  <button
                                    className="btn btn-sm btn-danger w-60px"
                                    onClick={() => handleDeleteMessageTemplate(message_template)}
                                  >
                                    {t('delete')}
                                  </button>
                                )}
                              </td>
                            )}
                            <td style={{ verticalAlign: 'super' }}>{message_template.name}</td>
                            <td style={{ verticalAlign: 'super' }}>{tdCategory}</td>
                            <td style={{ verticalAlign: 'super', textTransform: 'capitalize' }}>
                              {tdType.toLowerCase()}
                            </td>
                            <td style={{ verticalAlign: 'super' }}>{tdLanguage}</td>
                            <td style={{ verticalAlign: 'super', textTransform: 'capitalize' }}>
                              {message_template.status?.toLowerCase()}
                            </td>
                            <td style={{ verticalAlign: 'super' }}>
                              {message_template.internal_status === 'enabled' ? 'Yes' : 'No'}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="d-md-flex align-items-center">
                <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                  {t('pagination_info_1')} {(paginationParams.page - 1) * paginationParams.limit + 1}{' '}
                  {t('pagination_info_2')}{' '}
                  {paginationParams.page * paginationParams.limit < paginationParams.totalDocs
                    ? paginationParams.page * paginationParams.limit
                    : paginationParams.totalDocs}{' '}
                  {t('pagination_info_3')} {paginationParams.totalDocs} {t('pagination_info_4')}
                </div>

                <CustomPagination1 paginationParams={paginationParams} setPage={setPage} />
              </div>
            </div>
          </div>
        </div>

        <CustomModal1
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          modal_title={t('delete_message_template')}
          onSubmit={onDeleteModalSubmit}
          submitButtonText={t('confirm')}
        >
          <div className="alert alert-danger">
            <h5>
              <i className="fa fa-info-circle"></i> {t('alert')}
            </h5>
            <p>{t('delete_confirmation_text')}</p>
          </div>
        </CustomModal1>
      </Container>
    </>
  );
};

export default withTranslation()(MessageTemplates);
