import React, { useContext, useEffect, useMemo, useState } from 'react';
import ConversationContext, { useConversation } from './context/ConversationContext';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { usePermissions } from '../_component/context/AuthContext';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Chip, Popover, Typography } from '@mui/material';
import CustomerTag from '../components/customer-tag';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CustomerTagList from '../components/customer-tag-list';
import { getDisplayedTags } from '../helpers/subscription';

const MODE = {
  NONE: 'NONE',
  NEW_CHAT: 'NEW_CHAT',
};

dayjs.extend(relativeTime);

const ConversationRow = props => {
  const { t, data, index, style } = props;
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  const conversationContext = useContext(ConversationContext);
  const { customerID, updateCustomerIsBlocked, updateCustomerIsArchived } = useConversation();
  const [dropdown, setDropdown] = useState(false);


  // Extract data from react-window itemData.
  const rowData = data.data[index];

  const rowProps = useMemo(() => ({
    mode: data.rowProps.mode,
    profileIcon: 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000',
    name: rowData?.profile_name,
    lastMsg: rowData?.last_msg,
    lastMsgTime: rowData?.last_msg_time,
    reactionEmoji: rowData?.last_msg_reaction?.emoji,
    unread: !rowData?.last_msg_read,
    unread_count: rowData?.unread_count,
    messageSide: rowData?.messageSide,
    customerDoc: rowData?.customerDoc,
    customer_id: rowData?._id,
    phone: rowData?.phone_number,
    setShowAgentModal: data.rowProps.setShowAgentModal,
    updateAgentModalContent: data.rowProps.updateAgentModalContent,
    agents: rowData?.agents,
    agentGroups: rowData?.agent_groups,
    assignedAgents: rowData?.assigned_agents,
    assignedAgentGroups: rowData?.assigned_agent_groups,
    isBlocked: rowData?.is_blocked === true,
    isArchived: rowData?.is_archived === true,
    customerTags: Array.isArray(rowData?.customer_tags) ? rowData.customer_tags : [],
  }), [rowData, data.rowProps]); // Only recomputes when `rowData` or `data.rowProps` change

  if (data.data.length === index) {
    return (
      <span style={style} className="fw-light text-center">
        {t('end_of_list')}
      </span>
    );
  }
  
  const lastMsg = rowProps?.lastMsg?.split('<br>');

  const toggleMenu = () => {
    setDropdown(!dropdown);
  };

  const handleClickEditCustomer = customer => {
    console.log('handleClickEditCustomer()', { customer });
    navigate('/customers/add', { state: { customer: customer } });
  };

  const handleClickUpdateIsBlocked = e => {
    console.log('Invoke handleClickUpdateIsBlocked().');
    updateCustomerIsBlocked(rowProps.customer_id, !rowProps.isBlocked);
  };

  const handleClickUpdateIsArchived = e => {
    console.log('Invoke handleClickUpdateIsArchived().');
    updateCustomerIsArchived(rowProps.customer_id, !rowProps.isArchived);
  };

  return (
    <div
      className="conversation-row d-flex"
      style={style}
      onClick={() => {
        conversationContext.resetMessageLimit();
        conversationContext.updateWindowChat(rowProps.customer_id, rowProps.name, rowProps.phone, 20, {
          customerTags: rowProps.customerTags,
        });
        // console.log(`AGENT ID: `,rowProps.agent_id)
        rowProps.updateAgentModalContent(rowProps.customer_id, rowProps.agents, rowProps.agentGroups);

        // Mark message as read on click
        // if (rowProps.unread) {
        // rowProps.markMessageAsRead(rowProps.customer_id);
        // conversationContext.markMessageAsRead(rowProps.customer_id)
        // }
      }}
    >
      <div className="col-2 d-flex justify-content-center align-items-center">
        <div className="position-relative">
          {/* WhatsApp Icon */}
          <WhatsAppIcon style={{ fontSize: 50 }} />

          {/* Agent */}
          {Array.isArray(rowProps?.assignedAgents) && rowProps.assignedAgents.length > 0 && (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 0 }}
              overlay={
                <Tooltip placement="top-end">
                  {rowProps.assignedAgents.map((value, index) => (
                    <div key={index}>
                      {value.name}
                      {index < rowProps.assignedAgents.length - 1 && <br />}
                    </div>
                  ))}
                </Tooltip>
              }
            >
              <div
                style={{
                  position: 'absolute',
                  bottom: 0, // Distance from the bottom of the WhatsApp icon
                  left: -8,   // Distance from the left of the WhatsApp icon
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                  padding: '11px',
                  backgroundColor: '#000000',
                  color: '#ffffff',
                  width: '20px',
                  height: '20px',
                }}
              >
                <i className="fa fa-user-tie"></i>
              </div>
            </OverlayTrigger>
          )}

          {/* Agent Group */}
          {Array.isArray(rowProps?.assignedAgentGroups) && rowProps.assignedAgentGroups.length > 0 && (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 0 }}
              overlay={
                <Tooltip placement="top-end">
                  {rowProps.assignedAgentGroups.map((value, index) => (
                    <div key={index}>
                      {value.name}
                      {index < rowProps.assignedAgentGroups.length - 1 && <br />}
                    </div>
                  ))}
                </Tooltip>
              }
            >
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,  // Distance from the bottom of the WhatsApp icon
                  right: -8,   // Distance from the right of the WhatsApp icon
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                  padding: '11px',
                  backgroundColor: '#000000',
                  color: '#ffffff',
                  width: '20px',
                  height: '20px',
                }}
              >
                <i className="fa fa-user-group"></i>
              </div>
            </OverlayTrigger>
          )}
        </div>
      </div>

      <div className="col-10 px-2 d-flex flex-column justify-content-center">
        <div className="d-flex justify-content-between align-items-center">
          <span className="conversation-name">{rowProps.name}</span>
          {rowProps.mode === MODE.NONE && (
            <span className="fw-light last-msg-time">
              {rowProps.lastMsgTime != undefined ? dayjs().to(dayjs(rowProps.lastMsgTime)) : ''}
            </span>
          )}
        </div>
        {rowProps.mode === MODE.NONE && (
          <>
            <div className="d-flex justify-content-between">
              <span className="last-msg-line">
                {typeof rowProps.reactionEmoji === 'undefined'
                  ? lastMsg[0]
                  : rowProps.messageSide === 'from_business'
                  ? `${t('reaction_you_react_1')}${rowProps.reactionEmoji}${t('reaction_you_react_2')}${lastMsg[0]}${t(
                      'reaction_you_react_3'
                    )}`
                  : `${t('reaction_react_1')}${rowProps.reactionEmoji}${t('reaction_react_2')}${lastMsg[0]}${t(
                      'reaction_react_3'
                    )}`}
              </span>
              {/* {rowProps.messageSide == 'from_customer' ? rowProps.unread && <span className="unread-badge">{t("new_message")}</span> : <></>} */}
              {rowProps.customer_id !== customerID &&
              Number.isInteger(rowProps.unread_count) &&
              rowProps.unread_count > 0 ? (
                <span className="unread-badge">{rowProps.unread_count}</span>
              ) : (
                <></>
              )}
              <div className="dropdown">
                <button
                  className={`btn btn-default dropdown-toggle p-0 px-1 ${dropdown ? 'show' : ''}`}
                  type="button"
                  aria-expanded="false"
                  onClick={() => toggleMenu()}
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                >
                  <b className="caret m-auto"></b>
                </button>
                <ul
                  className={`dropdown-menu${dropdown ? ' show' : ''}`}
                  style={{
                    position: 'absolute',
                    inset: '0px 0px auto auto',
                    margin: 0,
                    transform: 'translate3d(-0.5px, 29px, 0px)',
                  }}
                >
                  <li>
                    <a
                      className="dropdown-item"
                      href="#1"
                      onClick={() => {
                        rowProps.setShowAgentModal(true);
                      }}
                    >
                      {t('assign_agent')}
                    </a>
                  </li>
                  {permissions?.edit_customer && (
                    <li>
                      <a
                        className="dropdown-item"
                        href="#2"
                        onClick={() => handleClickEditCustomer(rowProps.customerDoc)}
                      >
                        {t('edit_customer')}
                      </a>
                    </li>
                  )}
                  <li>
                    <a className="dropdown-item" href="#3" onClick={handleClickUpdateIsBlocked}>
                      {rowProps.isBlocked === true ? t('unblock') : t('block')}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#4" onClick={handleClickUpdateIsArchived}>
                      {rowProps.isArchived === true ? t('unarchive') : t('archive')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <CustomerTagList
              isBlocked={rowProps.isBlocked}
              isArchived={rowProps.isArchived}
              customerTags={rowProps.customerTags}
              style={{ paddingTop: 5, overflowX: 'hidden' }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(ConversationRow);
