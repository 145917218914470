import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { POST } from '../../../api/api-manager';
import { logout, changeLanguage, selectAuth } from '../../../store/reducers/auth';
import { selectUser } from '../../../store/reducers/user';
import { Link } from 'react-router-dom';
import { useSubscription } from '../../../_component/context/SubscriptionContext';
import { usePermissions } from '../../../_component/context/AuthContext';

const DropdownProfile = props => {
  const { i18n, t } = props;
  const dispatch = useDispatch();
  const { userName } = useSelector(selectAuth);
  const { permissions } = usePermissions();
  const {
    profile_pic
  } = useSelector(selectUser);
  const { activeSubscriptionPlan } = useSubscription();

  const [agentProfilePic, setAgentProfilePic] = useState(null);
  const localeLowercase = i18n.language.toLowerCase();

  useEffect(() => {
    setAgentProfilePic(profile_pic);
  }, [profile_pic]);
  useEffect(() => {
    console.log({ debugactiveSubscriptionPlan: activeSubscriptionPlan})
  }, [activeSubscriptionPlan]);

  return (
    <div className="navbar-item navbar-user dropdown">
      <a href="#/" className="navbar-link dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown">
        {/* <img src="/assets/img/user/user-13.jpg" alt="" />  */}
        <img
          className="profile-pic"
          src={
            agentProfilePic
              ? agentProfilePic
              : 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000'
          }
          alt="profile_pic"
        />
        <span>
          <span className="d-none d-md-inline">{userName}</span>
          <b className="caret"></b>
        </span>
      </a>
      <div className="dropdown-menu dropdown-menu-end me-1">
        {/* <a href="#/" className="dropdown-item">
          Edit Profile
        </a>
        <a href="#/" className="dropdown-item d-flex align-items-center">
          Inbox
          <span className="badge bg-danger rounded-pill ms-auto pb-4px">2</span>
        </a>
        <a href="#/" className="dropdown-item">
          Calendar
        </a>
        <a href="#/" className="dropdown-item">
          Settings
        </a> */}
        {/* <div className="dropdown-divider"></div> */}
        {false &&
          <div style={{
            display: 'none',
            // display: 'flex',
            // justifyContent: 'flex-end',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 5,
            fontWeight: 'bold',
            // padding: 'var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)',
            // paddingBottom: 10
            // padding: '0 8px 12px 8px',
            padding: '0 8px 8px 8px',
            marginBottom: 4,
            borderBottom: '1px solid #dddddd',
          }}>
            {/* <div
              style={{

              }}
            >
              {userName}
            </div> */}
            <div
              style={{
                background: 'linear-gradient(135deg, #1a3bfa, #55aaff)',
                color: '#ffffff',
                padding: '2px 6px',
                borderRadius: 4,
                // fontSize: 14,
                whiteSpace: 'nowrap',
              }}
            >
              {/* Current Plan: {t('starter')} {t('user')} */}
              {t('starter')} {t('user')}
            </div>
          </div>
        }
        <Link to="/update_profile" className="dropdown-item">
          <i className="fa fa-circle-user fa-fw me-1 text-dark"></i> {t('update_profile')}
        </Link>
        {permissions?.manage_subscription && (
          <Link to="/subscription" className="dropdown-item" style={{ display: 'flex', alignItems: 'center', gap: 5, whiteSpace: 'nowrap' }}>
            <i className="fa fa-credit-card fa-fw me-1 text-dark"></i>
            {t('subscription')}
            {typeof activeSubscriptionPlan !== 'undefined' &&
              <div
                style={{
                  background: 'linear-gradient(135deg, #1a3bfa, #55aaff)',
                  color: '#ffffff',
                  padding: '2px 6px',
                  borderRadius: 4,
                  // fontSize: 14,
                  whiteSpace: 'nowrap',
                }}
              >
                {activeSubscriptionPlan?.subscription_plan?.name?.[localeLowercase]}
              </div>
            }
          </Link>
        )}
        <a href="#" className="dropdown-item" onClick={() => dispatch(POST(logout, 'users/logout', {}))}>
          <i className="fa fa-door-open fa-fw me-1 text-dark"></i> {t('logout')}
        </a>
      </div>
    </div>
  );
};

export default withTranslation()(DropdownProfile);
