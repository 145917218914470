import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { apiGenerator } from '../api/api-manager';
import { withTranslation } from 'react-i18next';
import { AppSettings } from '../config/app-settings';
import { Store } from 'react-notifications-component';
import { Autocomplete, Checkbox, Chip, TextField } from '@mui/material';
import CustomerTag from '../components/customer-tag';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DoneIcon from '@mui/icons-material/Done';

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <DoneIcon fontSize="small" style={{ visibility: 'hidden', }} />;
const checkedIcon = <DoneIcon fontSize="small" />;

const AddCustomer1 = props => {
  const { t } = props;

  const appSettings = useContext(AppSettings);
  const navigate = useNavigate();
  const location = useLocation();
  const [editCustomer, setEditCustomer] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [customerTags, setCustomerTags] = useState([]);
  const [selectedCustomerTags, setSelectedCustomerTags] = useState([]);

  const nameRef = useRef();
  const phoneNumberRef = useRef();

  const onFormSubmit = event => {
    const formData = new FormData(event.currentTarget);

    const submitted_checkedbox = [];
    document.querySelectorAll(':checked').forEach(ele => {
      if (ele.value == 'on') {
        submitted_checkedbox.push(ele.id);
      }
    });
    event.preventDefault();

    var error = false;

    for (let [key, value] of formData.entries()) {
      // console.log(key, value);
      // check empty
      if (key == 'name' || key == 'phoneNumber') {
        if (value == '' || value == null || value == undefined) {
          error = true;
        }
      }
    }

    if (error) {
      // alert(`Please filled in required information`);
      Store.addNotification({
        message: `Please filled in required information`,
        type: 'danger',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
    } else {
      let requestBody = {
        profile_name: formData.get('name'),
        phone_number: formData.get('phoneNumber'),
        customer_tag_ids: selectedCustomerTags.map(selectedCustomerTag => selectedCustomerTag._id),
      };
      console.log(requestBody);

      if (editMode) {
        apiGenerator({
          method: 'PATCH',
          path: `customers/${editCustomer._id}`,
          body: requestBody,
        }).then(res => {
          // alert(res.data.message);
          Store.addNotification({
            message: res.data.message,
            type: 'success',
            insert: "top",
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 5000 },
          });
          if (res.status === 200) {
            navigate('/customers');
          }
        }).catch(function (error) {
          if (error.response?.data?.message) {
            // alert(error.response.data.message);
            Store.addNotification({
              message: error.response.data.message,
              type: 'danger',
              insert: "top",
              container: 'top-right',
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: { duration: 5000 },
            });
          }
        });
      } else {
        apiGenerator({
          method: 'POST',
          path: 'customers/create',
          body: requestBody,
        }).then(res => {
          // alert(res.data.message);
          Store.addNotification({
            message: res.data.message,
            type: 'success',
            insert: "top",
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 5000 },
          });
          if (res.status === 200) {
            navigate('/customers');
          }
        }).catch(function (error) {
          if (error.response?.data?.message) {
            // alert(error.response.data.message);
            Store.addNotification({
              message: error.response.data.message,
              type: 'danger',
              insert: "top",
              container: 'top-right',
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: { duration: 5000 },
            });
          }
        });
      }
    }
  };

  // ! Debug
  useEffect(() => {
    console.log({ selectedCustomerTags })
  }, [selectedCustomerTags]);

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');

    // return () => {
    //   appSettings.handleSetAppSidebarNone(false);
    //   appSettings.handleSetAppHeaderNone(false);
    // };
  }, [appSettings]);

  useEffect(() => {
    if (location.state?.customer) {
      setEditCustomer(location.state.customer);
      setEditMode(true);
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      const fetchCustomerTags = async () => {
        try {
          const response = await apiGenerator({
            method: 'GET',
            path: 'customer-tags', // Adjust the API path to get customers
            query: {
              params: {
                page: 1,
                // limit: 9999,
              },
            },
          });
    
          if (Array.isArray(response?.data?.data?.docs)) {
            setCustomerTags(response.data.data.docs);
          } else {
            setCustomerTags([]);
          }
        } catch (error) {
          console.error('Error fetching customers:', error);
        };
      };

      await fetchCustomerTags();
    })();
  }, []);

  useEffect(() => {
    if (typeof editCustomer === 'undefined') return;
    if (!Array.isArray(editCustomer?.customer_tag_ids)) return;

    const newSelectedCustomerTags = editCustomer.customer_tag_ids
      .map(customerTagId => customerTags.find(tag => tag._id === customerTagId))
      .filter(Boolean); // Removes any "undefined" values

    setSelectedCustomerTags(newSelectedCustomerTags);
  }, [editCustomer, customerTags])

  return (
    <Container>
      <div className="d-flex align-items-center">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={'/'}>Home</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to={'/customers'}>{t('customer_management')}</Link>
            </li>
            <li className="breadcrumb-item active">{editMode ? t('edit_customer') : t('add_customer')}</li>
          </ol>
          <h1 className="page-header">{editMode ? t('edit_customer') : t('add_customer')}</h1>
        </div>
      </div>

      <div className="row gx-4">
        <div className="col-lg-12">
          <Form onSubmit={onFormSubmit} id="customerForm">
            <div className="card border-0 mb-4">
              <div className="card-body p-3 text-dark fw-bold">
                <div className="row">
                  <div className="col-sm-12">
                  <fieldset>
                    <div className="row mb-3">
                      {/* Left Half: Name and Phone Number */}
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="name">
                            {t('name')}
                          </label>
                          <input
                            ref={nameRef}
                            type="text"
                            className="form-control"
                            name="name"
                            defaultValue={editMode ? editCustomer.profile_name : ''}
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label" htmlFor="phoneNumber">
                            {t('phone_number')}
                          </label>
                          <input
                            ref={phoneNumberRef}
                            type="text"
                            className="form-control"
                            name="phoneNumber"
                            defaultValue={editMode ? editCustomer.phone_number : ''}
                          />
                        </div>
                      </div>

                      {/* Right Half: Tags */}
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Tags</label>
                          <Autocomplete
                            multiple
                            options={customerTags}
                            value={selectedCustomerTags}
                            onChange={(event, newValue) => {
                              console.log('onChange()', { newValue });
                              setSelectedCustomerTags(newValue);
                            }}
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            getOptionLabel={option => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => {
                                const { key, ...tagProps } = getTagProps({ index });
                                // console.log('renderTags()', { value, option }) // ! Debug
                                const { name, border_color: borderColor, background_color: backgroundColor, text_color: textColor } = option;

                                return (
                                  <CustomerTag name={name} borderColor={borderColor} backgroundColor={backgroundColor} textColor={textColor} key={key} {...tagProps} />
                                );
                              })
                            }
                            renderOption={(props, option, { selected }) => {
                              const { key, ...optionProps } = props;
                              return (
                                <li key={key} {...optionProps}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    // style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.name}
                                </li>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  </div>
                </div>
              </div>

              <div className="card-footer bg-none d-flex p-3">
                <button type="submit" className="btn btn-primary w-100px ms-auto">
                  {t('submit')}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default withTranslation()(AddCustomer1);
