import { createContext, useContext, useEffect, useState } from 'react';

export const SubscriptionContext = createContext();

export function SubscriptionProvider({ children }) {
  const [activeSubscriptionPlan, setActiveSubscriptionPlan] = useState(undefined);

  useEffect(() => {
    console.log('useEffect on activeSubscriptionPlan', activeSubscriptionPlan);
  }, [activeSubscriptionPlan]);

  return (
    <SubscriptionContext.Provider
      value={{
        activeSubscriptionPlan,
        setActiveSubscriptionPlan,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}

export function useSubscription() {
  return useContext(SubscriptionContext);
}
