import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Button, Form, Container } from 'react-bootstrap';
import { Store } from 'react-notifications-component';
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  Checkbox,
  Chip,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { apiGenerator } from '../api/api-manager';
import { AppSettings } from '../config/app-settings';
import { throttle } from 'lodash';
import { useTranslation } from 'react-i18next';
import CustomerTag from '../components/customer-tag';
import { FixedSizeList } from 'react-window';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DoneIcon from '@mui/icons-material/Done';

const icon = <DoneIcon fontSize="small" style={{ visibility: 'hidden' }} />;
const checkedIcon = <DoneIcon fontSize="small" />;

function renderRow(props) {
  // console.log('renderRow()', { props })
  const { index, style, data } = props;
  const customer = data.customers[index];
  const removeRow = data.removeRow;

  return (
    // <ListItem style={style} key={index} component="div" disablePadding>
    //   <ListItemButton>

    //   </ListItemButton>
    // </ListItem>
    <ListItem
      style={style}
      key={index}
      secondaryAction={
        <IconButton edge="end" aria-label="delete" onClick={() => removeRow(customer)}>
          <CloseIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <WhatsAppIcon fontSize="large" />
      </ListItemAvatar>
      <ListItemText primary={customer.profile_name} secondary={customer.phone_number} />
    </ListItem>
  );
}

const CustomerTagForm = props => {
  const { t } = useTranslation();

  const appSettings = useContext(AppSettings);
  const navigate = useNavigate();
  const location = useLocation();

  const [editCustomerTag, setEditCustomerTag] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [borderColor, setBorderColor] = useState('#2641D1');
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [textColor, setTextColor] = useState('#000000');
  const [name, setName] = useState('');
  const [customers, setCustomers] = useState([]);
  const [searchCustomerQuery, setSearchCustomerQuery] = useState('');
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  // Function to fetch customers from an API
  const fetchCustomers = useCallback(async query => {
    if (!query) {
      setCustomers([]);
      return;
    }

    try {
      const response = await apiGenerator({
        method: 'GET',
        path: 'customers', // Adjust the API path to get customers
        query: {
          params: {
            keyword: query,
          },
        },
      });

      if (Array.isArray(response?.data?.data)) {
        setCustomers(response.data.data);
      } else {
        setCustomers([]);
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  }, []);

  // Throttled version of fetchCustomers
  const fetchCustomersThrottled = useMemo(() => throttle(fetchCustomers, 750), [fetchCustomers]);

  // Handle form submission for both creating and updating a customer tag
  const onFormSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const requestBody = {
      name: formData.get('name'),
      border_color: borderColor,
      background_color: backgroundColor,
      text_color: textColor,
      customer_ids: selectedCustomers.map(selectedCustomer => selectedCustomer._id),
    };

    if (editMode) {
      // Update API call

      requestBody.remove_from_customer_ids = editCustomerTag.customers
        .filter(customer => !selectedCustomers.some(selected => selected._id === customer._id))
        .map(customer => customer._id);

      apiGenerator({
        method: 'PATCH',
        path: `customer-tags/${editCustomerTag._id}`,
        body: requestBody,
      })
        .then(res => {
          Store.addNotification({
            message: res?.data?.message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: { duration: 5000 },
          });
          if (res.status === 200) {
            navigate('/customer-tags');
          }
        })
        .catch(error => {
          Store.addNotification({
            message: error?.response?.data?.message || t('error_updating_customer_tag'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: { duration: 5000 },
          });
        });
    } else {
      // Create API call
      apiGenerator({
        method: 'POST',
        path: 'customer-tags', // Adjust the API path to create customer tag
        body: requestBody,
      })
        .then(res => {
          Store.addNotification({
            message: res?.data?.message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: { duration: 5000 },
          });
          if (res.status === 200) {
            navigate('/customer-tags');
          }
        })
        .catch(error => {
          Store.addNotification({
            message: error?.response?.data?.message || t('error_creating_customer_tag'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: { duration: 5000 },
          });
        });
    }
  };

  // ! Debug
  useEffect(() => {
    console.log({ customers });
  }, [customers]);

  useEffect(() => {
    console.log({ selectedCustomers });
  }, [selectedCustomers]);

  // Set up the form for editing a tag if available
  useEffect(() => {
    if (location.state?.customerTag) {
      setEditCustomerTag(location.state.customerTag);
      setName(location.state.customerTag.name);
      setBorderColor(location.state.customerTag.border_color);
      setBackgroundColor(location.state.customerTag.background_color);
      setTextColor(location.state.customerTag.text_color);
      setEditMode(true);
    }
  }, [location]);

  useEffect(() => {
    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');
  }, [appSettings]);

  // useEffect(() => {
  //   if (typeof editCustomerTag === 'undefined') return;
  //   if (!Array.isArray(editCustomerTag?.customers)) return;

  //   console.log({ test123: editCustomerTag.customers, customers });
  //   const newSelectedCustomers = editCustomerTag.customers
  //     .map(customer => customers.find(customerOption => customerOption._id === customer._id))
  //     .filter(Boolean); // Removes any "undefined" values

  //   setSelectedCustomers(newSelectedCustomers);
  // }, [editCustomerTag, customers]);
  useEffect(() => {
    if (typeof editCustomerTag === 'undefined') return;
    if (!Array.isArray(editCustomerTag?.customers)) return;

    setSelectedCustomers(editCustomerTag.customers);
  }, [editCustomerTag]);

  return (
    <Container>
      <div className="d-flex align-items-center">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={'/'}>Home</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to={'/customer-tags'}>{t('customer_tag_management')}</Link>
            </li>
            <li className="breadcrumb-item active">{editMode ? t('edit_customer_tag') : t('add_customer_tag')}</li>
          </ol>
          <h1 className="page-header">{editMode ? t('edit_customer_tag') : t('add_customer_tag')}</h1>
        </div>
      </div>

      <Form onSubmit={onFormSubmit} id="customerTagForm">
        <div className="row gx-4">
          <div className="col-lg-3">
            <div className="card border-0 mb-4">
              <div className="card-body p-3 text-dark">
                <div className="mb-3">
                  <label className="form-label">{t('preview')}</label>
                  <div
                    style={{
                      backgroundColor: '#efeae2',
                      padding: '15px',
                      borderRadius: '10px',
                      overflowX: 'auto',
                    }}
                  >
                    {name !== '' && (
                      <CustomerTag
                        name={name}
                        borderColor={borderColor}
                        backgroundColor={backgroundColor}
                        textColor={textColor}
                      />
                    )}
                  </div>
                </div>

                {/* <div className="mb-3">
                  <label className="form-label">{t('border_color')}</label>
                  <input
                    type="color"
                    className="form-control form-control-color w-100"
                    value={borderColor}
                    title="Choose your color"
                    onChange={e => setBorderColor(e.target.value)}
                  />
                </div> */}

                <div className="mb-3">
                  <label className="form-label">{t('background_color')}</label>
                  <input
                    type="color"
                    className="form-control form-control-color w-100"
                    value={backgroundColor}
                    title="Choose your color"
                    onChange={e => setBackgroundColor(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">{t('text_color')}</label>
                  <input
                    type="color"
                    className="form-control form-control-color w-100"
                    value={textColor}
                    title="Choose your color"
                    onChange={e => setTextColor(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-9">
            <div className="card border-0 mb-4">
              <div className="card-body p-3 text-dark fw-bold">
                <div className="row">
                  {/* First row - Name input takes col-sm-6 */}
                  <div className="col-sm-6">
                    <label className="form-label" htmlFor="name">
                      {t('name')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>
                </div>

                <hr />

                <div className="row">
                  {/* Second row - Autocomplete takes col-sm-6 */}
                  <div className="col-sm-6">
                    <label className="form-label">{t('customers_who_have_the_tag_assigned')}</label>
                    <Autocomplete
                      multiple
                      filterOptions={x => x}
                      options={customers}
                      isOptionEqualToValue={(option, value) => option._id === value._id}
                      getOptionKey={option => option._id}
                      getOptionLabel={option => option.profile_name}
                      inputValue={searchCustomerQuery} // Bind inputValue to prevent clearing the input
                      value={selectedCustomers}
                      onInputChange={(event, newSearchCustomerQuery) => {
                        // console.log('onInputChange', { event, eventType: event.type, newSearchCustomerQuery });
                        if (event.type === 'change') {
                          setSearchCustomerQuery(newSearchCustomerQuery); // Set the input value to persist after typing
                          fetchCustomersThrottled(newSearchCustomerQuery);
                        }
                      }}
                      onChange={(event, newSelected) => {
                        console.log('onChange', { newSelected, });
                        setSelectedCustomers(newSelected); // Update selected customers
                      }}
                      renderInput={params => (
                        <TextField {...params} size="small" variant="outlined" placeholder={t('type_to_search')} />
                      )}
                      renderTags={(value, getTagProps) => null} // Prevent displaying chips
                      renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps } = props;
                        return (
                          <li key={key} {...optionProps}>
                            <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                            {option.profile_name}
                          </li>
                        );
                      }}
                      noOptionsText={t('no_results')}
                      disableClearable
                      clearOnBlur={false}
                      openOnFocus // Keeps dropdown open when the input is focused
                    />
                  </div>

                  {/* Second row - FixedSizeList takes the remaining col-sm-6 */}
                  <div className="col-sm-6">
                    <label className="form-label">&nbsp;</label>
                    <Card variant="outlined">
                      {selectedCustomers.length > 0 ? (
                        <FixedSizeList
                          height={400}
                          // width={360}
                          itemSize={60}
                          itemCount={selectedCustomers.length}
                          overscanCount={5}
                          itemData={{
                            customers: selectedCustomers,
                            removeRow: data => {
                              console.log('removeRow()', { data });

                              // Filter out the customer by _id
                              setSelectedCustomers(prevState =>
                                prevState.filter(customer => customer._id !== data._id)
                              );
                            },
                          }}
                        >
                          {renderRow}
                        </FixedSizeList>
                      ) : (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                          color="text.secondary"
                          padding={2}
                        >
                          <Typography variant="h6" align="center">
                            {t('no_selections')}
                          </Typography>
                        </Box>
                      )}
                    </Card>
                  </div>
                </div>
              </div>

              <div className="card-footer bg-none d-flex p-3">
                <Button type="submit" className="btn btn-primary w-100px ms-auto">
                  {t('submit')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default CustomerTagForm;
