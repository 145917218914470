const { apiGenerator } = require('../../api/api-manager');

export const getSubscription = async () => {
  return new Promise(resolve => {
    apiGenerator({
      method: 'POST',
      path: `subscription/checkLastSubscription`,
      body: {},
    }).then(res => {
      return resolve(res?.data ? res.data : undefined);
    });
  });
};

export const getDisplayedTags = (tags, maxLength = 20) => {
  let displayedTags = [];
  let hiddenTags = [];
  let currentLength = 0;

  for (let tag of tags) {
    if (currentLength + tag.name.length <= maxLength) {
      displayedTags.push(tag);
      currentLength += tag.name.length;
    } else {
      hiddenTags.push(tag);
    }
  }

  return { displayedTags, hiddenTags };
}