import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { getAgentGroupList, getParentAgentGroupList } from '../store/reducers/agentGroups';
import { selectAgentGroups, addAgentGroup, updateAgentGroup, deleteAgentGroup } from '../store/reducers/agentGroups';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { apiGenerator, GET } from '../api/api-manager';
import { withTranslation } from 'react-i18next';
import { AppSettings } from '../config/app-settings';
import { Store } from 'react-notifications-component';

const AddAgentGroups1 = props => {
  const { t } = props;

  const dispatch = useDispatch();
  const appSettings = useContext(AppSettings);
  const [editAgentGroup, setEditAgentGroup] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const groupNameRef = useRef();
  const groupTypeRef = useRef();
  const parentGroupIdRef = useRef();
  const [name, setName] = useState('');
  const [type, setType] = useState('parent');
  const [parentGroupId, setParentGroupId] = useState(undefined);

  const [parentAgentGroupListState, setParentAgentGroupListState] = useState([]);

  const { agentGroupList, agentGroupPaginationParams, loading, parentAgentGroupList } = useSelector(selectAgentGroups);

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');

    // return () => {
    //   appSettings.handleSetAppSidebarNone(false);
    //   appSettings.handleSetAppHeaderNone(false);
    // };
  }, [appSettings]);

  // Test
  useEffect(() => {
    console.log({ name, type, parentGroupId });
  }, [name, type, parentGroupId]);

  useEffect(() => {
    dispatch(GET(getParentAgentGroupList, 'user_groups/parent', {}));
  }, [agentGroupList]);

  useEffect(() => {
    setParentAgentGroupListState(parentAgentGroupList);
  }, [parentAgentGroupList]);

  useEffect(() => {
    if (location.state?.agentGroup) {
      setEditAgentGroup(location.state.agentGroup);
      setEditMode(true);

      // Initialize input values
      setName(location.state.agentGroup.name);
      setType(location.state.agentGroup.parent_group_id == null ? 'parent' : 'subGroup');
      setParentGroupId(location.state.agentGroup.parent_group_id != null ? location.state.agentGroup.parent_group_id : undefined);
    }
  }, [location]);

  const onFormSubmit = event => {
    const formData = new FormData(event.currentTarget);

    console.log({ event, formData });

    const submitted_checkedbox = [];
    document.querySelectorAll(':checked').forEach(ele => {
      if (ele.value == 'on') {
        submitted_checkedbox.push(ele.id);
      }
    });
    event.preventDefault();

    var error = false;

    for (let [key, value] of formData.entries()) {
      // console.log(key, value);
      // check empty
      if (key == 'name' || key == 'parent_group_id' || key == 'type') {
        if (value == '' || value == null || value == undefined) {
          error = true;
        }
      }
    }

    if (error) {
      // alert(`Please filled in required information`);
      Store.addNotification({
        message: `Please filled in required information`,
        type: 'danger',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
    } else {
      let requestBody = {
        page: 1,
        limit: 1,
        name: formData.get('name'),
        parent_group_id: formData.get('parent_group_id'),
        type: formData.get('type'),
      };
      console.log(requestBody);

      if (editMode) {
        apiGenerator({
          method: 'PATCH',
          path: `user_groups/${editAgentGroup._id}`,
          body: requestBody,
        })
          .then(res => {
            // alert(res.data.message);
            Store.addNotification({
              message: res.data.message,
              type: 'success',
              insert: "top",
              container: 'top-right',
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: { duration: 5000 },
            });
            if (res.status === 200) {
              navigate('/agent_groups');
            }
          })
          .catch(function (error) {
            if (error.response?.data?.message) {
              // alert(error.response.data.message);
              Store.addNotification({
                message: error.response.data.message,
                type: 'danger',
                insert: "top",
                container: 'top-right',
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 5000 },
              });
            }
          });
      } else {
        apiGenerator({
          method: 'POST',
          path: 'user_groups/create',
          body: requestBody,
        })
          .then(res => {
            // alert(res.data.message);
            Store.addNotification({
              message: res.data.message,
              type: res.data.status ? 'success' : 'danger',
              insert: "top",
              container: 'top-right',
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: { duration: 5000 },
            });
            if (res.data.status) {
              navigate('/agent_groups');
            }
          })
          .catch(function (error) {
            if (error.response?.data?.message) {
              // alert(error.response.data.message);
              Store.addNotification({
                message: error.response.data.message,
                type: 'danger',
                insert: "top",
                container: 'top-right',
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 5000 },
              });
            }
          });
      }
    }
  };

  return (
    <Container>
      <div className="d-flex align-items-center">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={'/'}>Home</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to={'/agent_groups'}>{t('agent_group_management')}</Link>
            </li>
            <li className="breadcrumb-item active">{editMode ? t('edit_agent_group') : t('add_agent_group')}</li>
          </ol>
          <h1 className="page-header">{editMode ? t('edit_agent_group') : t('add_agent_group')}</h1>
        </div>
      </div>

      <div className="row gx-4">
        <div className="col-lg-12">
          <Form onSubmit={onFormSubmit} id="agentGroupForm">
            <div className="card border-0 mb-4">
              <div className="card-body p-3 text-dark fw-bold">
                <div className="row">
                  <div className="col-sm-12">
                    <fieldset>
                      <div className="row mb-3">
                        <div className="col-sm-6">
                          <label className="form-label" htmlFor="name">
                            {t('group_name')}
                          </label>
                          <input
                            ref={groupNameRef}
                            type="text"
                            className="form-control"
                            name="name"
                            defaultValue={editMode ? editAgentGroup.name : ''}
                            value={name}
                            onChange={e => {
                              setName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-sm-6">
                          <label className="form-label">{t('group_type')}</label>
                          <select
                            className="form-select"
                            name="type"
                            value={type}
                            onChange={e => {
                              setType(e.target.value);
                            }}
                          >
                            <option value="parent">{t('main_agent_group')}</option>
                            <option value="subGroup">{t('second_level_agent_group')}</option>
                          </select>
                        </div>
                      </div>

                      {type === 'subGroup' && (
                        <div className="row mb-3">
                          <div className="col-sm-6">
                            <label className="form-label">{t('main_agent_group')}</label>
                            <select
                              className="form-select"
                              name="parent_group_id"
                              value={parentGroupId}
                              onChange={e => {
                                setParentGroupId(e.target.value);
                              }}
                            >
                              {parentAgentGroupListState.map(parentGroup => {
                                return (
                                  <option value={`${parentGroup._id}`} key={parentGroup._id}>
                                    {parentGroup.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      )}
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="card-footer bg-none d-flex p-3">
                <button type="submit" className="btn btn-primary w-100px ms-auto">
                  {t('submit')}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default withTranslation()(AddAgentGroups1);
