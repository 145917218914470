import React, { useMemo } from 'react';
import CustomerTag from '../customer-tag';
import { useTranslation } from 'react-i18next';
import { getDisplayedTags } from '../../helpers/subscription';
import { Popover } from '@mui/material';

const CustomerTagList = ({ isBlocked, isArchived, customerTags, maxTagCharacterLength = 20, style, ...props } = {}) => {
  // console.log('<CustomerTagList />', { maxTagCharacterLength })
  const { t } = useTranslation();
  const [tagAnchorEl, setTagAnchorEl] = React.useState(null);
  const open = Boolean(tagAnchorEl);

  const { displayedTags, hiddenTags } = useMemo(() => {
    let newTags = [];

    if (isBlocked) {
      newTags.push({
        _id: '_BLOCKED',
        name: t('blocked'),
        background_color: 'red',
        text_color: '#ffffff',
      });
    }

    if (isArchived) {
      newTags.push({
        _id: '_ARCHIVED',
        name: t('archived'),
        background_color: 'darkorange',
        text_color: '#ffffff',
      });
    }

    if (Array.isArray(customerTags)) {
      newTags = [...newTags, ...customerTags];
    }

    return getDisplayedTags(newTags, maxTagCharacterLength);
  }, [t, isBlocked, isArchived, customerTags, maxTagCharacterLength]);

  const handleCustomerTagPopoverOpen = event => {
    setTagAnchorEl(event.currentTarget);
  };

  const handleCustomerTagPopoverClose = () => {
    setTagAnchorEl(null);
  };

  return (
    <>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handleCustomerTagPopoverOpen}
        onMouseLeave={handleCustomerTagPopoverClose}
        {...props}
        style={{ display: 'flex', alignItems: 'center', gap: 5, width: 'fit-content', ...style }}
      >
        {/* Render the displayed tags */}
        {displayedTags.map(customerTag => {
          const { name, background_color: backgroundColor, text_color: textColor } = customerTag;
          return (
            <CustomerTag key={customerTag._id} name={name} backgroundColor={backgroundColor} textColor={textColor} />
          );
        })}

        {/* Render a message for hidden tags */}
        {Array.isArray(hiddenTags) && hiddenTags.length > 0 && <b>{`... ${hiddenTags.length} More`}</b>}
      </div>

      {/* Popover for hidden tags */}
      {hiddenTags.length > 0 && (
        <Popover
          sx={{ pointerEvents: 'none' }}
          open={open}
          anchorEl={tagAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleCustomerTagPopoverClose}
          disableAutoFocus
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: 5, padding: 5, flexWrap: 'wrap', maxWidth: '200px' }}>
            {/* Render the hidden tags here */}
            {hiddenTags.map(customerTag => {
              const { name, background_color: backgroundColor, text_color: textColor } = customerTag;
              return (
                <CustomerTag
                  key={customerTag._id}
                  name={name}
                  backgroundColor={backgroundColor}
                  textColor={textColor}
                />
              );
            })}
          </div>
        </Popover>
      )}
    </>
  );
};

export default CustomerTagList;
