import React from 'react';
import { Chip } from '@mui/material';

const CustomerTag = ({ name, borderColor, backgroundColor, textColor, ...props } = {}) => {
  return (
    <Chip
      {...props}
      label={name}
      size="small"
      // variant="outlined"
      style={{
        borderRadius: '5px',
        // borderColor: borderColor,
        backgroundColor: backgroundColor,
        color: textColor,
      }}
    />
  );
};

export default CustomerTag;
